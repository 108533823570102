import {
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/react'

export const SkeletonItems: React.FC = () => {
  return (
      <IonList>
        {[...Array(10)].map((e, i) => (
          <IonItem key={i}>
            <IonThumbnail slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel>
              <h3>
                <IonSkeletonText animated style={{ width: '50%' }} />
              </h3>
              <p>
                <IonSkeletonText animated style={{ width: '80%' }} />
              </p>
              <p>
                <IonSkeletonText animated style={{ width: '60%' }} />
              </p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
  )
}

export default SkeletonItems
