import './Login.css'
import { Link, useHistory } from 'react-router-dom'
import {
  IonPage,
  IonContent,
  IonInput,
  IonButton,
  IonToast,
  IonLoading,
  IonText,
  IonItemDivider,
  IonImg
} from '@ionic/react'
import { useEffect, useRef, useState } from 'react'

import {
  getUserData,
  loginUser,
  logoutUser,
  sendRegistrationToken,
  sendVerification,
  requestForToken,
  getUserNotifications
} from '../firebase'
import { useDispatch } from 'react-redux'
import {
  setExerciseNotification,
  setReminderNotification,
  setUserData,
  setUserState,
  setWaterNotification
} from '../redux/actions'
import { useTranslation } from 'react-i18next'
import { User } from 'firebase/auth'
import {
  NOTIFICATION_ALCOHOL_SUGAR,
  NOTIFICATION_EXERCISE,
  NOTIFICATION_WATER
} from '../components/common/DbConstants'
import {
  getZonedConfigData,
  getZonedConfigDataExercise
} from '../components/notifications/timezone-utils'

const Login: React.FC = () => {
  const { t } = useTranslation()

  const history = useHistory()
  const dispatch = useDispatch()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMessage] = useState('')
  const [busy, setBusy] = useState<boolean>(false)
  const [unverifiedUser, setUnverifiedUser] = useState<User | null>(null)
  const [inactiveUser, setInactiveUser] = useState<User | null>(null)

  const ionContentRef = useRef<HTMLIonContentElement>(null)

  async function login() {
    setBusy(true)
    setUnverifiedUser(null)

    try {
      const result = await loginUser(username, password)
      const { success, user: userCredentials } = result

      if (!success || !userCredentials) {
        setToastMessage(t('error.login'))
        setShowToast(true)

        // TODO: Report login error to API server
        return
      }

      if (!userCredentials.emailVerified) {
        setUnverifiedUser(result.user)

        logoutUser()
        localStorage.clear()
        return
      }

      // user is logged in (firebase) and email is verified
      dispatch(setUserState(result.user))
      localStorage.setItem('AUTH_TOKEN', userCredentials.uid)

      // get user data from API server
      const userData = await getUserData()

      // if user data is empty, logout user
      if (!userData || userData === '') {
        throw new Error('User data is empty. Logging out user.')
      }

      // if user is inactive, logout user
      if (!userData?.active) {
        setInactiveUser(result.user)
        logoutUser()
        localStorage.clear()

        return
      }

      dispatch(setUserData(userData))

      const userNotifications = await getUserNotifications()
      userNotifications?.forEach((notification: any) => {
        if (notification.notificationType === NOTIFICATION_WATER) {
          const configDataZoned = getZonedConfigData(
            JSON.parse(notification.configData)
          )
          dispatch(setWaterNotification(configDataZoned))
        }
        if (notification.notificationType === NOTIFICATION_EXERCISE) {
          const configDataZoned = getZonedConfigDataExercise(
            JSON.parse(notification.configData)
          )
          dispatch(setExerciseNotification(configDataZoned))
        }
        if (notification.notificationType === NOTIFICATION_ALCOHOL_SUGAR) {
          const configDataZoned = getZonedConfigData(
            JSON.parse(notification.configData)
          )
          dispatch(setReminderNotification(configDataZoned))
        }
      })

      const fcmToken = await requestForToken()
      if (fcmToken != null && userData.id) {
        sendRegistrationToken(fcmToken, userData.id)
      } // send token to API server

      if (userData.isAdmin) {
        history.replace('/admin')
      } else {
        history.replace('/dashboard')
      }
    } catch (error) {
      console.error(error)

      logoutUser()
      localStorage.clear()

      setToastMessage(t('error.login'))
      setShowToast(true)
    } finally {
      setBusy(false)
    }
  }

  function resendEmail() {
    setUnverifiedUser(null)
    sendVerification(unverifiedUser)
  }

  useEffect(() => {
    const keyboardWillShow = (event: any) => {
      if (ionContentRef.current) {
        ionContentRef.current.style.setProperty(
          '--keyboard-offset',
          `${event.keyboardHeight}px`
        )
      }
    }

    const keyboardWillHide = () => {
      if (ionContentRef.current) {
        ionContentRef.current.style.setProperty('--keyboard-offset', '0px')
      }
    }

    window.addEventListener('keyboardWillShow', keyboardWillShow)
    window.addEventListener('keyboardWillHide', keyboardWillHide)

    return () => {
      window.removeEventListener('keyboardWillShow', keyboardWillShow)
      window.removeEventListener('keyboardWillHide', keyboardWillHide)
    }
  }, [])

  return (
    <IonPage className="login">
      {busy && (
        <IonLoading
          message={t('message.please_wait')}
          isOpen={busy}
          duration={0}
        />
      )}
      <IonContent
        fullscreen
        scrollEvents={true}
        className="ion-padding"
        ref={ionContentRef}
        style={{ '--keyboard-offset': '0px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IonImg
            class="ion-padding login-logo"
            src="/assets/logo.png"
            style={{ maxWidth: 400 }}
          ></IonImg>
        </div>
        <div className="ion-padding">
          <IonInput
            className="ion-padding"
            placeholder={t('email')}
            onIonChange={(e: any) => setUsername(e.target.value.trim())}
            type="email"
          />
          <IonInput
            className="ion-padding"
            placeholder={t('password')}
            type="password"
            onIonChange={(e: any) => setPassword(e.target.value)}
          />

          <div className="reset-password">
            <p>
              {t('message.forgot_password')}{' '}
              <Link to="/reset_password">{t('recover')}</Link>
            </p>
          </div>

          <IonButton
            className="ion-margin-top ion-margin-bottom"
            expand="block"
            onClick={login}
          >
            {t('login')}
          </IonButton>

          <div className="ion-padding ion-float-right">
            <p>
              {t('message.dont_have_account')}{' '}
              <Link to="/register">{t('register')}</Link>
            </p>
          </div>

          {inactiveUser && (
            <>
              <IonItemDivider />
              <IonText>
                {t('message.inactive_user', {
                  email: inactiveUser.email
                })}
              </IonText>
            </>
          )}

          {unverifiedUser && (
            <>
              <IonItemDivider />
              <br />
              <br />
              <IonText>
                {t('message.verification_required', {
                  email: unverifiedUser.email
                })}
              </IonText>
              <br />
              <br />
              <IonButton
                onClick={resendEmail}
                expand="block"
                className="ion-no-margin"
              >
                {t('message.resend_verification_email')}
              </IonButton>
            </>
          )}
        </div>
      </IonContent>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />
    </IonPage>
  )
}

export default Login
