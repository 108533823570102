import { Link, useHistory } from 'react-router-dom'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonButton,
  IonToast,
  IonSpinner,
  useIonViewDidEnter,
  IonBackButton,
  IonItem,
  IonList,
  IonLabel
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { loginUser, logoutUser, sendVerification } from '../firebase'
import { useTranslation } from 'react-i18next'
import { chevronBack, closeOutline } from 'ionicons/icons'
import { useCreateUser } from '../api/user'

const Register: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [registerText, setRegisterText] = useState(<></>)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMessage] = useState('')
  const [enableRegister, setEnableRegister] = useState(false)

  const createUser = useCreateUser()

  useIonViewDidEnter(() => {
    setRegisterText(<>{t('register')}</>)
  })

  // Mapping of error codes to user-friendly messages
  interface ErrorMap {
    'auth/internal-error': string;
    'auth/error-creating-user': string;
    'auth/weak-password': string;
    'auth/missing-fields': string;
    'auth/email-already-exists': string;
    'auth/invalid-email': string;
    [key: string]: string; // Index signature
  }

  // Create an instance of the errorMap object
  const errorMap: ErrorMap = {
    'auth/internal-error': t('error.internal_error'),
    'auth/error-creating-user': t('error.creating_user'),
    'auth/weak-password': t('error.password_length'),
    'auth/missing-fields': t('error.missing_fields'),
    'auth/email-already-exists': t('error.email_already_exists'),
    'auth/invalid-email': t('error.email_invalid')
  }

  useEffect(() => {
    if (username.trim() !== '' && email.trim() !== '' && password.trim() !== '' && confirmPassword.trim() !== '') {
      setEnableRegister(true)
    } else {
      setEnableRegister(false)
    }
  }, [username, email, password, confirmPassword])

  function validateFields (): [boolean, string] {
    const emailRegex = /\S+@\S+\.\S+/

    if (!emailRegex.test(email)) {
      return [false, t('error.email_invalid')]
    } else if (password !== confirmPassword) {
      return [false, t('error.password_mismatch')]
    } else if (username.trim() === '' || password.trim() === '') {
      return [false, t('error.credentials_required')]
    } else if (password.length < 6) {
      return [false, t('error.password_length')]
    } else if (!/\d/.test(password)) {
      return [false, t('error.password_number')]
    }

    return [true, '']
  }

  async function register () {
    // Reset toast message
    setToastMessage('')
    setShowToast(false)

    try {
      setRegisterText(<IonSpinner />)

      const [isValid, errorMessage] = validateFields()

      if (!isValid) {
        setToastMessage(errorMessage)
        setShowToast(true)
        setRegisterText(<>{t('register')}</>)
        return
      }

      await createUser.mutateAsync({
        name: username,
        email,
        password
      },
      {
        onSuccess: async (data) => {
          if (data?.success) {
            setToastMessage(t('message.registered'))

            // Send verification email
            const res = await loginUser(email, password)
            if (res.success && !res.user?.emailVerified) {
              await sendVerification(res.user)
              await logoutUser()
            }

            // Resetting form fields only if createUser is successful
            setUsername('')
            setEmail('')
            setPassword('')
            setConfirmPassword('')

            // Redirect to login page
            history.push('/login')
          } else {
            setToastMessage(t('error.generic'))
          }
        },
        onError: (data) => {
          console.error({ error: data })
          const errorCode = data?.response?.data?.error?.code as keyof ErrorMap
          setToastMessage(errorMap[errorCode] || t('error.generic'))
        }
      })
    } catch (error) {
      console.error('Unexpected error:', error)
    } finally {
      setShowToast(true)
      setRegisterText(<>{t('register')}</>)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButton slot="start">
            <IonBackButton
              color={'light'}
              text={''}
              icon={chevronBack}
              defaultHref="login"
            />
          </IonButton>
          <IonTitle>{t('register')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="ion-margin-bottom">
          <IonItem>
            <IonLabel position="floating">{t('username')}</IonLabel>
            <IonInput
              type="text"
              placeholder={t('username')}
              required
              onIonChange={(e) => e.target.value && setUsername(e.target.value.toString())}
              value={username}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t('email')}</IonLabel>
            <IonInput
              type="email"
              placeholder={t('email')}
              required
              onIonChange={(e) => e.target.value && setEmail(e.target.value.toString())}
              value={email}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t('password')}</IonLabel>
            <IonInput
              placeholder={t('password')}
              type="password"
              required
              onIonChange={(e) => e.target.value && setPassword(e.target.value.toString())}
              value={password}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t('confirm_password')}</IonLabel>
            <IonInput
              placeholder={t('confirm_password')}
              type="password"
              required
              onIonChange={(e) => e.target.value && setConfirmPassword(e.target.value.toString())}
              value={confirmPassword}
            />
          </IonItem>
          <IonButton
            expand="block"
            color="primary"
            className='ion-margin'
            onClick={register}
            disabled={!enableRegister}
          >
            {registerText}
          </IonButton>
        </IonList>
        <p className="ion-text-center ion-margin-top">
          {t('message.already_have_account')} <Link to="/login">{t('login')}</Link>
        </p>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        duration={10000}
        htmlAttributes={{ tabindex: undefined }}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              setShowToast(false)
            }
          }
        ]}
      />
    </IonPage>
  )
}

export default Register
