import {
  IonButton,
  IonContent,
  IonImg,
  IonLoading,
  IonPage
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { handleUserAction } from '../firebase'

const EmailVerification: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [verifiedMessageHeader, setVerifiedMessageHeader] = useState<string>('')
  const [verifiedMessage, setVerifiedMessage] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)

  useEffect(() => {
    setBusy(true)

    handleUserAction(window.location.href)
      .then((response) => {
        if (response?.success) {
          setVerifiedMessageHeader(t('message.email_verified_header'))
          setVerifiedMessage(t('message.email_verified'))
        } else {
          setVerifiedMessageHeader(t('error.email_not_verified_header'))
          setVerifiedMessage(t('error.email_not_verified'))
        }
      })
      .catch(() => {
        setVerifiedMessageHeader(t('error.email_not_verified_header'))
        setVerifiedMessage(t('error.email_not_verified'))
      })

    setShowMessage(true)
    setBusy(false)
  }, [])

  return (
    <IonPage className="login">
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonContent className="ion-padding">
        <div className="login-logo-container">
          <IonImg className="ion-padding login-logo" src="/assets/logo.png" />
        </div>

        {showMessage &&
          <>
            <div style={{ textAlign: 'center', marginBottom: 50 }}>
              <h3>{verifiedMessageHeader}</h3>
              <p>{verifiedMessage}</p>
            </div>
            <IonButton expand="block" onClick={() => { history.push('/login') }}>{t('action.go_to_login')}</IonButton>
          </>
        }
      </IonContent>
    </IonPage>
  )
}

export default EmailVerification
