import { GiSportMedal } from 'react-icons/gi'
import { BiRun, BiSwim, BiWalk } from 'react-icons/bi'
import { MdSportsKabaddi, MdSportsHandball } from 'react-icons/md'
import { BsBicycle } from 'react-icons/bs'
import { CgGym } from 'react-icons/cg'
import { EXERCISE_TYPE_WALKING, EXERCISE_TYPE_RUNNING, EXERCISE_TYPE_BIKING, EXERCISE_TYPE_SWIMMING, EXERCISE_TYPE_GYM, EXERCISE_TYPE_CONTACT_SPORT, EXERCISE_TYPE_TEAM_SPORT } from './DbConstants'
import { t } from 'i18next'

export const exerciseIcon = (exercise: number|string) => {
  switch (parseInt(exercise.toString())) {
    case EXERCISE_TYPE_WALKING:
      return <BiWalk />
    case EXERCISE_TYPE_RUNNING:
      return <BiRun />
    case EXERCISE_TYPE_BIKING:
      return <BsBicycle />
    case EXERCISE_TYPE_SWIMMING:
      return <BiSwim />
    case EXERCISE_TYPE_GYM:
      return <CgGym />
    case EXERCISE_TYPE_CONTACT_SPORT:
      return <MdSportsKabaddi />
    case EXERCISE_TYPE_TEAM_SPORT:
      return <MdSportsHandball />
    default:
      return <GiSportMedal />
  }
}
export const exerciseDescription = (exercise: string) => {
  switch (parseInt(exercise)) {
    case EXERCISE_TYPE_WALKING:
      return t('exercise.walking')
    case EXERCISE_TYPE_RUNNING:
      return t('exercise.running')
    case EXERCISE_TYPE_BIKING:
      return t('exercise.biking')
    case EXERCISE_TYPE_SWIMMING:
      return t('exercise.swimming')
    case EXERCISE_TYPE_GYM:
      return t('exercise.gym')
    case EXERCISE_TYPE_CONTACT_SPORT:
      return t('exercise.contact_sport')
    case EXERCISE_TYPE_TEAM_SPORT:
      return t('exercise.team_sport')
    default:
      return exercise
  }
}
