import './HungerScale.css'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { useState } from 'react'
import {
  addRecordType,
  ITEM_HUNGER_SCALE,
  RECORD_INSERT
} from '../common/DbConstants'
import { chevronBack } from 'ionicons/icons'
import RecordDate from '../common/RecordDate'

export const HungerScale: React.FC = () => {
  const [key, setKey] = useState(0)
  const [selectedScale, selectScale] = useState(0)
  const [toastMsg, setToastMsg] = useState(null)
  const history = useHistory()
  const [recordDate, setRecordDate] = useState(new Date().toISOString())

  const reset = () => {
    selectScale(0)
    setKey(key + 1)
    setRecordDate(new Date().toISOString())
  }

  const [createRecord] = useMutation(RECORD_INSERT)

  const addRecord = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (selectedScale === 0) {
      setToastMsg(t('message.scale_required'))
      return
    }
    await addRecordType(createRecord, ITEM_HUNGER_SCALE, null, null, null, selectedScale, '', recordDate)

    history.push('/actions')
    reset()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.hunger_scale')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent key={key}>
        <RecordDate recordDate={recordDate} setRecordDate={setRecordDate} />
        <div className="hunger-scale">
          {Array.from({ length: 10 }, (_, i) => i + 1).map((x: number) =>
            <div key={x} className={`scale ${x === selectedScale ? 'selected' : selectedScale !== 0 ? 'unselected' : ''}`} onClick={() => selectScale(x)}>
              <span className={`${'hunger-value-' + x}`}>{x}</span>
              <span className={'scale-' + x}></span>
              <span className="desc">{t('hunger_scale.' + x)}</span>
            </div>
          )}
        </div>
        <IonButton className="ion-padding" expand="block" onClick={addRecord}>
          {t('save')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg(null)}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />
    </IonPage>
  )
}

export default HungerScale
