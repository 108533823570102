import './MoodSelector.css'
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonText,
  useIonViewWillEnter
} from '@ionic/react'
import { t } from 'i18next'
import { REASON_EVENT, REASON_IRRELEVANT } from './DbConstants'
import { useDispatch, useSelector } from 'react-redux'
import { setReasonSelected } from '../../redux/actions'

export const ReasonSelector: React.FC = () => {
  const dispatch = useDispatch()
  const reasonSelected = useSelector((state: any) => state.reasonSelected)

  useIonViewWillEnter(() => {
    dispatch(setReasonSelected(null))
  })

  function handleReasonSelected (mood: string | undefined) {
    dispatch(setReasonSelected(mood))
  }

  return <div className="reason-selector ion-padding ion-margin-top">
  <IonText>{t('message.reason')}</IonText>
  <IonSegment onIonChange={e => handleReasonSelected(e.detail.value)} value={reasonSelected}>
  <IonSegmentButton value={REASON_EVENT.toString()} className="event">
    <IonLabel>{t('reason.event')}</IonLabel>
  </IonSegmentButton>
  <IonSegmentButton value={REASON_IRRELEVANT.toString()} className="irrelevant">
    <IonLabel>{t('reason.irrelevant')}</IonLabel>
  </IonSegmentButton>
</IonSegment>
</div>
}

export default ReasonSelector
