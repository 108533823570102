import { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { requestForToken, onMessageListener } from '../../firebase'

const Notification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' })
  function ToastDisplay () {
    return (
      <span>
        <b>{notification?.title}</b>
        <br />{notification?.body}
      </span>
    )
  };
  const notify = () => toast(<ToastDisplay />,
    {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff'
      }
    }
  )

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  // requestForToken calls getToken method, that subscribes our app to push notifications
  requestForToken()

  onMessageListener()
    .then((payload: any) => {
      setNotification({ title: payload?.data?.title, body: payload?.data?.body })
    })
    .catch((err: any) => console.log('failed: ', err))

  return (
    <Toaster />
  )
}

export default Notification
