import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reducer from './redux/reducer'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query';
import { createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'

import Notification from './components/notifications/Notification'

const queryClient = new QueryClient();
const store = createStore(reducer, devToolsEnhancer({}))

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Notification />
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister()
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
