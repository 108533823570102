import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { addDays, format, isWithinInterval, parseISO, endOfMonth, startOfMonth } from 'date-fns'
import { t } from 'i18next'
import { calendar, closeOutline } from 'ionicons/icons'
import { Fragment, useEffect, useState } from 'react'
import { ITEM_SUGAR, ITEM_WATER, ITEM_ALCOHOL, ITEM_EXERCISE, ITEM_HISTAMINE, ITEM_HUNGER_SCALE } from '../DbConstants'

interface FiltersProps {
  data: any,
  setItems: any
}

export const Filters: React.FC<FiltersProps> = ({ data, setItems }) => {
  const [showDateEndModal, setShowDateEndModal] = useState(false)
  const [showDateStartModal, setShowDateStartModal] = useState(false)
  const [filters, setFilters] = useState({
    water: false,
    sugar: false,
    alcohol: false,
    exercise: false,
    tmh: false,
    hunger_scale: false,
    dateStart: startOfMonth(new Date()),
    dateEnd: endOfMonth(new Date())
  })

  useEffect(() => {
    if (data) {
      let filteredItems = data.filter((item: { createdAt: string }) => {
        return isWithinInterval(new Date(parseInt(item.createdAt)), { start: filters.dateStart, end: filters.dateEnd })
      })

      if (!filters.sugar && !filters.water && !filters.alcohol && !filters.exercise && !filters.tmh && !filters.hunger_scale) {
        setItems(filteredItems)
      } else {
        filteredItems = filteredItems.filter((item: { createdAt: string; itemId: number }) => {
          if (item.itemId && item.itemId === ITEM_SUGAR && !filters.sugar) return false
          if (item.itemId && item.itemId === ITEM_WATER && !filters.water) return false
          if (item.itemId && item.itemId === ITEM_ALCOHOL && !filters.alcohol) return false
          if (item.itemId && item.itemId === ITEM_EXERCISE && !filters.exercise) return false
          if (item.itemId && item.itemId === ITEM_HISTAMINE && !filters.tmh) return false
          if (item.itemId && item.itemId === ITEM_HUNGER_SCALE && !filters.hunger_scale) return false

          return true
        })
        setItems([...filteredItems])
      }
    }
  }, [data, filters])

  return (
    <Fragment>
      <IonAccordionGroup className="ion-margin-top ion-margin-bottom" expand='inset'>
        <IonAccordion value="filters">
          <IonItem slot="header">
            <IonLabel>{t('filters.filters')}</IonLabel>
          </IonItem>
          <IonList slot="content">
            <div className='filters'>
              <IonItemGroup className='filters-type'>
                <IonChip color={filters.sugar ? 'primary' : 'medium'} outline={!filters.sugar} onClick={() => {
                  setFilters({ ...filters, sugar: !filters.sugar })
                }}>
                  <IonLabel>{t('action.sugar')}</IonLabel>
                </IonChip>

                <IonChip color={filters.water ? 'primary' : 'medium'} outline={!filters.water} onClick={() => {
                  setFilters({ ...filters, water: !filters.water })
                }}>
                  <IonLabel>{t('action.water')}</IonLabel>
                </IonChip>

                <IonChip color={filters.alcohol ? 'primary' : 'medium'} outline={!filters.alcohol} onClick={() => {
                  setFilters({ ...filters, alcohol: !filters.alcohol })
                }}>
                  <IonLabel>{t('action.alcohol')}</IonLabel>
                </IonChip>

                <IonChip color={filters.exercise ? 'primary' : 'medium'} outline={!filters.exercise} onClick={() => {
                  setFilters({ ...filters, exercise: !filters.exercise })
                }}>
                  <IonLabel>{t('action.exercise')}</IonLabel>
                </IonChip>

                <IonChip color={filters.tmh ? 'primary' : 'medium'} outline={!filters.tmh} onClick={() => {
                  setFilters({ ...filters, tmh: !filters.tmh })
                }}>
                  <IonLabel>{t('action.custom')}</IonLabel>
                </IonChip>

                <IonChip color={filters.hunger_scale ? 'primary' : 'medium'} outline={!filters.hunger_scale} onClick={() => {
                  setFilters({ ...filters, hunger_scale: !filters.hunger_scale })
                }}>
                  <IonLabel>{t('action.hunger_scale')}</IonLabel>
                </IonChip>
              </IonItemGroup>
              <hr />
              <IonItemGroup className='filters-date'>
                <IonItem lines='none'
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%'

                    }}
                  >
                    <IonChip color='primary' outline={false} id="filter-date-start"
                      style={{
                        padding: '1.2rem 1rem'
                      }}
                      onClick={() => {
                        setShowDateStartModal(true)
                      }}
                    >
                      <IonLabel
                        style={{
                          position: 'absolute',
                          top: '4px',
                          left: '15px'
                        }}
                      >{t('filters.from')}:</IonLabel>
                      <IonLabel color='primary'
                        style={{
                          position: 'relative',
                          top: '8px',
                          left: '0px'
                        }}
                      >{format(filters.dateStart, 'dd-MM-yyy')}</IonLabel>
                      <IonIcon icon={calendar} />
                    </IonChip>

                    <IonLabel>
                      <p
                        style={{
                          color: 'var(--ion-color-primary-shade)',
                          fontSize: '1.5rem'
                        }}
                      >/</p>
                    </IonLabel>

                    <IonChip color='primary' outline={false} id="filter-date-end"
                      style={{
                        padding: '1.2rem 1rem'
                      }}
                      onClick={() => {
                        setShowDateEndModal(true)
                      }}>
                      <IonLabel
                        style={{
                          position: 'absolute',
                          top: '4px',
                          left: '15px'
                        }}
                      >{t('filters.to')}:</IonLabel>
                      <IonLabel color='primary'
                        style={{
                          position: 'relative',
                          top: '8px',
                          left: '0px'
                        }}
                      >{format(filters.dateEnd, 'dd-MM-yyy')}</IonLabel>
                      <IonIcon icon={calendar} />
                    </IonChip></div>
                </IonItem>
              </IonItemGroup>
            </div>
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>

      <IonModal isOpen={showDateStartModal}
        style={{
          '--min-width': '300px',
          '--max-width': '500px',
          display: 'flex',
          justifyContent: 'center'
        }}
        onDidDismiss={() => {
          setShowDateStartModal(false)
        }}
        initialBreakpoint={0.60}
        breakpoints={[0.40, 0.60, 0.80, 1]}
        showBackdrop={true}
        backdropDismiss={true}
        canDismiss
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowDateStartModal(false)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>{t('filters.from')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding center"
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <IonDatetime
              presentation="date"
              max={`${format(filters.dateEnd, 'yyyy-MM-dd')}`}
              onIonChange={(ev) => {
                const date = ev?.detail?.value ? parseISO((ev?.detail?.value as string).split('T')[0]) : new Date()
                setFilters({ ...filters, dateStart: date })
              }}
            /></span>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showDateEndModal}
        style={{
          '--min-width': '300px',
          '--max-width': '500px',
          display: 'flex',
          justifyContent: 'center'
        }}
        onDidDismiss={() => {
          setShowDateEndModal(false)
        }}
        initialBreakpoint={0.60}
        breakpoints={[0.40, 0.60, 0.80, 1]}
        showBackdrop={true}
        backdropDismiss={true}
        canDismiss
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowDateEndModal(false)}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>{t('filters.to')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding center"
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <IonDatetime
              presentation="date"
              min={`${format(addDays(filters.dateStart, 1), 'yyyy-MM-dd')}`}
              onIonChange={(ev) => {
                const date = ev?.detail?.value ? parseISO((ev?.detail?.value as string).split('T')[0] + 'T23:59:59') : new Date()
                setFilters({ ...filters, dateEnd: date })
              }}
            /></span>
        </IonContent>
      </IonModal>
    </Fragment>
  )
}
