import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { people } from 'ionicons/icons'
import { useHistory } from 'react-router'

export const AdminIndex: React.FC = () => {
  const history = useHistory()

  console.log('Admin index')

  const goTo = (link: string) => () => {
    if (link) {
      history.push('/admin/' + link)
    }
  }

  const module = (title: string, icon: string, link: string = '') => {
    return (
      <div className="module" onClick={goTo(link)}>
        <div className="module-inner">
          <IonIcon icon={icon} />
          <div>{t(title)}</div>
        </div>
      </div>
    )
  }

  return (
    <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <IonTitle>{t('title.admin')}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <div className="actions-container">
        {module('title.users', people, 'users')}
      </div>
    </IonContent>
    </IonPage>

  )
}

export default AdminIndex
