import { Redirect, Route } from 'react-router-dom'
import {
  IonApp,
  IonLoading,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './App.css'

import Login from './pages/Login'
import Register from './pages/Register'
import ResetPasswordRequest from './pages/ResetPasswordRequest'
import ResetPassword from './pages/ResetPassword'
import EmailVerification from './pages/EmailVerification'

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useEffect, useState } from 'react'
import { getCurrentUser, getUserData, logoutUser } from './firebase'
import './i18n'
import Dashboard from './pages/Dashboard'
import { t } from 'i18next'
import { setUserData } from './redux/actions'
import { useDispatch } from 'react-redux'
import Admin from './pages/Admin'

setupIonicReact({
  swipeBackEnabled: false
})

let darkTheme = localStorage.getItem('darkTheme')
if (!darkTheme) {
  darkTheme = 'true'
  localStorage.setItem('darkTheme', `${darkTheme}`)
}
document.body.classList.toggle('dark', darkTheme === 'true')

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('AUTH_TOKEN')
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const RoutingSystem: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/reset_password" component={ResetPasswordRequest} />
              <Route exact path="/confirm_reset_password" component={ResetPassword} />
              <Route exact path="/email_verification" component={EmailVerification} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route path="/admin" component={Admin} />
              <Route path="/action" component={Dashboard} />
              <Route exact path="/actions">
                <Redirect to="/dashboard" />
              </Route>
              <Route exact path="/admin_users">
                <Redirect to="/admin" />
              </Route>
              <Route exact path="/profile">
                <Redirect to="/dashboard" />
              </Route>
            </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  )
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)
  useEffect(() => {
    getCurrentUser().then(async (user: any) => {
      setBusy(false)
      let isAdmin = false

      if (user) {
        const userData = await getUserData()
        if (!userData || userData === '') {
          await logoutUser()
          localStorage.clear()
        }
        dispatch(setUserData(userData))
        isAdmin = userData.isAdmin
      }

      if (!user) {
        if (window.location.pathname === '/action') {
          const url = new URL(window.location.href)
          const mode = url.searchParams.get('mode') || ''
          switch (mode) {
            case 'resetPassword':
              window.location.href = '/confirm_reset_password' + url.search
              break
            case 'verifyEmail':
              window.location.href = '/email_verification' + url.search
              break
            default:
              window.location.href = '/login'
          }
        }

        if (window.location.pathname !== '/login' &&
            window.location.pathname !== '/action' &&
            window.location.pathname !== '/confirm_reset_password' &&
            window.location.pathname !== '/email_verification'
        ) {
          window.location.href = '/login'
        }
      }

      const urlsToDashboard = ['/', '/login', '/register', '/action', '/reset_password', '/confirm_reset_password', '/email_verification']
      if (user && (urlsToDashboard.includes(window.location.pathname))) {
        const goto = isAdmin ? '/admin' : '/dashboard'
        console.log('redirect to ' + goto)
        console.log('goto', goto)
        window.location.href = goto
      }
    })
  })

  return (
    <IonApp>
      {busy
        ? (
        <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />
          )
        : (
        <RoutingSystem />
          )}
    </IonApp>
  )
}

export default App
