import './FollowUps.css'
import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow
} from '@ionic/react'
import { t } from 'i18next'
import { SORTABLE_DATE_FORMAT } from './DbConstants'
import moment from 'moment'

interface FollowUpsProps {
  items: any
}

export const FollowUps: React.FC<FollowUpsProps> = ({ items }) => {
  const itemSortableDate = (record: any) =>
    moment.unix(record.appointmentDate / 1000).format(SORTABLE_DATE_FORMAT)

  const item = (i: any) => {
    if (!i.weight && !i.muscleMass && !i.waterPercentage && !i.viscelarFat) {
      return <></>
    }

    return (
      <IonItem
        key={i.id}
        style={{
          borderBottom: '2px solid #2c2c2c'
        }}
      >
        <IonGrid>
          {i.weight && (
            <IonRow>
              <IonCol size="7" text-center>
                <IonLabel>{t('field.weight')}:</IonLabel>
              </IonCol>
              <IonCol size="2" text-center>
                <span style={{ fontSize: '1em', color: 'gray' }}>
                  {i.weight}
                </span>
              </IonCol>
            </IonRow>
          )}
          {i.muscleMass && (
            <IonRow>
              <IonCol size="7" text-center>
                <IonLabel>{t('field.muscle_mass')}:</IonLabel>
              </IonCol>
              <IonCol size="2" text-center>
                <span style={{ fontSize: '1em', color: 'gray' }}>
                  {i.muscleMass}
                </span>
              </IonCol>
            </IonRow>
          )}
          {i.waterPercentage && (
            <IonRow>
              <IonCol size="7" text-center>
                <IonLabel>{t('field.fat_percentage')}:</IonLabel>
              </IonCol>
              <IonCol size="2" text-center>
                <span style={{ fontSize: '1em', color: 'gray' }}>
                  {i.waterPercentage}
                </span>
              </IonCol>
            </IonRow>
          )}
          {i.viscelarFat && (
            <IonRow>
              <IonCol size="7" text-center>
                <IonLabel>{t('field.viscelar_fat')}:</IonLabel>
              </IonCol>
              <IonCol size="2" text-center>
                <span style={{ fontSize: '1em', color: 'gray' }}>
                  {i.viscelarFat}
                </span>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonItem>
    )
  }

  const dates = items.map((r: { appointmentDate: any }) => itemSortableDate(r))

  const uniqueDates = Array.from(new Set<any>(dates)).sort().reverse()

  const accordions = uniqueDates.map((d: string, i: number) => (
    <IonAccordion
      value={d}
      key={d}
      style={{
        borderRadius:
          i === 0
            ? '5px 5px 0 0'
            : i === uniqueDates.length - 1
              ? '0 0 5px 5px'
              : '0'
      }}
    >
      <IonItem slot="header">
        <IonLabel>
          {moment(d).format(process.env.REACT_APP_DATE_FORMAT)}
        </IonLabel>
      </IonItem>
      <IonList slot="content">
        {items
          .filter((i: any) => itemSortableDate(i) === d)
          .map((i: any) => item(i))
          .sort((a: any, b: any) =>
            a.appointmentDate < b.appointmentDate ? 1 : -1
          )}
      </IonList>
    </IonAccordion>
  ))

  return <IonAccordionGroup multiple={true}>{accordions}</IonAccordionGroup>
}
