import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: {
      action: {
        add_follow_up: 'Añadir seguimiento',
        add_user: 'Añadir usuario',
        alcohol: 'Alcohol',
        exercise: 'Actividad física',
        food: 'Comida',
        hunger_scale: 'Escala de hambre',
        search: 'Buscar',
        sugar: 'Azúcares y procesados',
        water: 'Agua',
        histamine: 'Test de Medición de Histamina',
        custom: 'Personalizado',
        reset_password: 'Restablecer contraseña',
        go_to_login: 'Ir a inicio',
        record_date: 'Fecha',
        change_date: 'Cambiar fecha',
        change: 'Cambiar',
        updated: 'Actualizado',
        glucose: 'Glucosa en sangre',
        glucose_unit: 'mg/dL',
        fast: 'Ayunas',
        bp: 'Presión arterial',
        bp_unit: 'mmHg',
        systolic: 'Sistólica',
        diastolic: 'Diastólica',
        add_glucose: 'Añadir registro',
        add_glucose_2: 'Añadir Medición de Glucosa',
        glucose_saved: 'Medición de glucosa guardada',
        glucose_deleted: 'Medición de glucosa eliminada',
        add_bp: 'Añadir registro',
        add_bp_2: 'Añadir Medición de Presión Arterial',
        bp_saved: 'Medición de presión arterial guardada',
        bp_deleted: 'Medición de presión arterial eliminada'
      },
      actions: 'Acciones',
      cancel: 'Cancelar',
      confirm_password: 'Confirmar contraseña',
      disable: 'Deshabilitar',
      enable: 'Habilitar',
      confirm: 'Confirmar',
      email: 'Correo electrónico',
      username: 'Nombre de usuario',
      date: 'Fecha',
      no_data: 'No hay datos',
      duration: {
        minutes: 'Minutos',
        repeats: 'Repeticiones'
      },
      error: {
        credentials_required:
          '<strong>Campos Requeridos:</strong> Ambos, correo electrónico y contraseña, son necesarios para continuar.',
        generic:
          '<strong>Oops, algo salió mal.</strong> Por favor, inténtalo de nuevo o contacta con soporte.',
        login:
          '<strong>Error al iniciar sesión.</strong> Por favor, verifica tu correo electrónico y contraseña.',
        password_mismatch:
          '<strong>Las contraseñas no coinciden.</strong> Asegúrate de que ambas contraseñas sean iguales.',
        password_length:
          '<strong>Contraseña muy corta.</strong> La contraseña debe contener al menos 6 caracteres.',
        password_number:
          '<strong>Requisito de contraseña:</strong> Incluye al menos un número en tu contraseña.',
        email_required:
          '<strong>Correo Electrónico Requerido.</strong> Por favor, introduce una dirección de correo electrónico.',
        email_not_found:
          'El correo electrónico introducido no es válido o no se encuentra registrado',
        email_not_verified_header:
          'La petición para verificar tu correo electrónico ha caducado o ya se ha usado el enlace',
        email_not_verified: 'Genere un nuevo enlace de verificación, por favor',
        reset_password: 'Error intentando restablecer la contraseña',
        password_change_failed: 'Error intentando cambiar la contraseña',
        email_already_exists:
          '<strong>Lo sentimos, ese correo electrónico ya está registrado.</strong>',
        email_invalid:
          '<strong>Correo electrónico no válido.</strong> Por favor, introduce una dirección de correo electrónico válida.',
        internal_error:
          '<strong>Error interno del servidor.</strong> Inténtalo de nuevo más tarde o contacta con el servicio de soporte.',
        creating_user:
          '<strong>Error al crear la cuenta.</strong> Por favor, revisa los campos y vuelve a intentarlo.',
        missing_fields:
          '<strong>Faltan campos obligatorios.</strong> Por favor, completa todos los campos necesarios para continuar.',
        glucose_invalid: 'Por favor, introduzca un valor de glucosa válido',
        date_invalid: 'Por favor, introduzca una fecha válida',
        deleting_glucose: 'Error al eliminar la medición de glucosa',
        saving_bp: 'Error al guardar la medición de presión arterial',
        bp_invalid:
          'Por favor, introduzca los valores de presión arterial válidos',
        deleting_bp: 'Error al eliminar la medición de presión arterial'
      },
      exercise: {
        walking: 'Caminar',
        running: 'Correr',
        biking: 'Bicicleta',
        swimming: 'Natación',
        gym: 'Gimnasio',
        contact_sport: 'Deporte de contacto',
        team_sport: 'Deporte de equipo',
        custom: 'Personalizado'
      },
      field: {
        appointment_date: 'Fecha de la cita',
        dark_mode: 'Modo oscuro',
        date: 'Fecha',
        description: 'Descripción',
        email: 'Correo electrónico',
        fat: 'Grasa',
        muscle_mass: 'Masa muscular',
        name: 'Nombre',
        physical_data: 'Datos físicos',
        viscelar_fat: 'Grasa visceral',
        fat_percentage: 'Porcentaje de grasa',
        weight: 'Peso',
        new_password: 'Nueva contraseña',
        confirm_new_password: 'Confirmar nueva contraseña'
      },
      hunger_scale: {
        1: 'Desmayado, mareado de hambre',
        2: 'Hambre voraz',
        3: 'Hambriento',
        4: 'Ligeramente hambriento',
        5: 'Ni hambriento ni lleno',
        6: 'Cómodamente lleno',
        7: 'Lleno',
        8: 'Incómodamente lleno',
        9: 'Hinchando, muy lleno',
        10: 'Sientes náuseas'
      },
      item: {
        glass: 'Vaso',
        shotLiquor: 'Chupito/Licor',
        wine: 'Copa de vino',
        beer: 'Caña de cervezca',
        mixedDrink: 'Combinado',
        large_bottle: 'Botella grande',
        medium_bottle: 'Botella mediana',
        small_bottle: 'Botella pequeña',
        standard_bottle: 'Botella'
      },
      login: 'Iniciar sesión',
      logout: 'Cerrar sesión',
      message: {
        already_have_account: '¿Ya estás registrado?',
        appoinment_date_in_future:
          'La fecha de la cita no puede ser en el futuro',
        at_least_one_exercise_required: 'Seleccione al menos un ejercicio',
        at_least_one_value_required: 'Al menos un valor es requerido',
        exercise_added: 'Actividad física añadida',
        description_required: 'La descripción es requerida',
        dont_have_account: '¿No tienes una cuenta?',
        how_did_you_feel: '¿Cómo te sentiste?',
        inactive_user:
          'Su usuario está deshabilitado, póngase en contacto con su nutricionista.',
        mood_required: 'El estado de ánimo es requerido',
        place_your_goal: 'Indique su objetivo',
        place_your_goal_quantity: 'Indique cantidad',
        please_wait: 'Por favor espere...',
        quantity_required: 'Indique la cantidad, por favor',
        reason: 'Razón',
        reason_required: 'La razón es necesaria',
        registered:
          'Usuario registrado con éxito. <strong>Recibirás un correo electrónico para confirmar tu dirección de correo.</strong> Si no encuentras el mensaje en tu bandeja de entrada, por favor verifica tu carpeta de <em>spam</em> o <em>correo no deseado</em>.',
        resend_verification_email: 'Reenviar correo de verificación',
        scale_required: 'Por favor, seleccione una escala',
        verification_required:
          'El usuario {{email}} no está verificado. Por favor compruebe su correo y verifique su cuenta.',
        user_fields_mandatory: 'Los campos de usuario son obligatorios',
        invalid_email: 'Email inválido',
        email_already_in_use: 'El email ya está en uso',
        user_already_exists: 'El usuario ya existe',
        forgot_password: '¿Olvidaste tu contraseña?',
        check_inbox:
          'Por favor, comprueba la bandeja de entrada de tu correo electrónico.',
        password_change_success: 'Tu contraseña se ha cambiado correctamente',
        email_verified_header: '¡Felicidades!',
        email_verified:
          'Tu cuenta ha sido verificada correctamente. Ahora puedes iniciar sesión.'
      },
      mood: {
        happy: 'Feliz',
        sorry: 'Arrepentid@'
      },
      my_goals: {
        alcohol: {
          days_without: 'Días sin',
          only_events: 'Sólo en eventos'
        },
        exercise: {
          days_3_per_week: '3 días por semana',
          hours_per_day: 'Horas por día',
          custom: 'Personalizado'
        },
        sugar: {
          challenge_21_days: 'Reto 21 días',
          days_without: 'Días sin',
          only_events: 'Sólo en eventos'
        },
        water: {
          litres_per_day: 'Litros/día'
        }
      },
      password: 'Contraseña',
      profile: 'Perfil',
      register: 'Regístrate',
      save: 'Guardar',
      recover: 'Restablecer',
      go_back: 'Volver',
      reset_password: 'Restablecer contraseña',
      reason: {
        event: 'Evento',
        irrelevant: 'Sin motivo'
      },
      placeholder: {
        description: 'Descripción',
        units: 'Unidades',
        physical_data: '--'
      },
      title: {
        admin: 'Administración',
        follow_up: 'Seguimiento',
        graphics: 'Gráficos',
        hunger_scale: 'Escala de hambre',
        my_goals: 'Mis objetivos',
        my_records: 'Mis Registros',
        new_follow_up: 'Nuevo Seguimiento',
        new_user: 'Nuevo Usuario',
        records: 'Registros',
        user_records: 'Registros de {{name}}',
        users: 'Usuarios',
        glucose: 'Glucosa',
        blood_pressure: 'Presión arterial',
        glucose_records: 'Historial'
      },
      motivational_quotes: {
        0: '¡No te vengas abajo! La fuerza está en tus ganas de lograrlo 💪',
        1: '¡Qué un tropiezo no te aleje de tu meta! Sigue adelante. 🏃‍♀️',
        2: 'Cuando sientas que vas a rendirte, piensa en porqué empezaste. 🧘',
        3: 'Tus objetivos no tienen fecha, ¡sigue adelante! 🙌',
        4: 'No te rindas, ¡continúa el viaje y persigue tus sueños! ❤',
        5: 'Si te rindes cuando las cosas se ponen difíciles, nunca llegarás a tu meta. ¡Ve a por ello! ✊'
      },
      notifications: 'Notificaciones',
      delete_profile: 'Eliminar perfil',
      delete_profile_confirm: '¿Desea eliminar el perfil? Esta acción no es reversible.',
      notification: {
        water_on:
          'Notificación activada. Se enviará un aviso si no se registra ingesta de agua en ',
        hours: ' hora(s)',
        on: 'Hora inicio',
        off: 'Hora fin',
        frequency: 'Frecuencia (h)',
        activated: 'Activar notificación',
        which_hour_reminder: '¿A qué hora quieres recibir el recordatorio?',
        alcohol_sugar: 'Alcohol/Azúcar',
        reminder_message:
          'Se le enviará una notificación a la hora configurada si no registra ingesta de alcohol/azúcares en las últimas 24 horas'
      },
      week: {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo'
      },
      filters: {
        filters: 'Filtros',
        to: 'Hasta',
        from: 'Desde'
      },
      messages: {
        bugs_suggestions: '¿Alguna sugerencia o error?',
        subject: 'Asunto',
        message: 'Mensaje',
        messages: 'Mensajes',
        send: 'Enviar',
        sent: 'Mensaje enviado 📩',
        error_sending_message: 'Error al enviar el mensaje',
        subject_placeholder: 'Asunto del mensaje',
        description_placeholder:
          'Proporcione una descripción más detallada de su sugerencia o error...',
        confirm_message: '¿Está seguro de que desea enviar el mensaje?',
        cancel: 'Cancelar'
      }
    }
  },
  en: {
    translation: {
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      register: 'Register',
      email: 'Email',
      password: 'Password'
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
