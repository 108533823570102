import axios, { AxiosError } from 'axios'
import { useMutation, UseMutationResult, useQuery } from 'react-query'
import { BASE_URL } from '../types/constants'
import { ErrorResponse, SuccessResponse } from '../types/interfaces'

type CreateBloodPressureInput = {
  systolic: number
  diastolic: number
  date: string
}

type KnownError = AxiosError<ErrorResponse>

const createBloodPressure = async (
  input: CreateBloodPressureInput
): Promise<SuccessResponse> => {
  const response = await axios.post<SuccessResponse>(
    `${BASE_URL}/v2/bloodpressure`,
    input,
    {
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
    }
  )
  return response.data
}

export const useCreateBloodPressure = (): UseMutationResult<
  SuccessResponse,
  KnownError,
  CreateBloodPressureInput
> => {
  return useMutation<SuccessResponse, KnownError, CreateBloodPressureInput>(
    createBloodPressure
  )
}

const fetchBloodPressureRecords = async (limit: number, offset = 0) => {
  const response = await axios.get(`${BASE_URL}/v2/bloodpressure`, {
    params: { limit, offset },
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
  })
  return response.data
}

export const useBloodPressureRecords = (limit: number, offset = 0) => {
  return useQuery(['bloodPressureRecords', { limit, offset }], () =>
    fetchBloodPressureRecords(limit, offset)
  )
}

const deleteBloodPressure = async (id: string): Promise<SuccessResponse> => {
  const response = await axios.delete<SuccessResponse>(
    `${BASE_URL}/v2/bloodpressure/${id}`,
    {
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
    }
  )
  return response.data
}

export const useDeleteBloodPressure = (): UseMutationResult<
  SuccessResponse,
  KnownError,
  string
> => {
  return useMutation<SuccessResponse, KnownError, string>(deleteBloodPressure)
}
