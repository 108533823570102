import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route } from 'react-router'
import { people, person } from 'ionicons/icons'
import Profile from './Profile'
import { IonReactRouter } from '@ionic/react-router'
import AdminIndex from '../components/admin/AdminIndex'
import Users from '../components/admin/Users'
import Messages from '../components/messages/Messages'

export const Admin: React.FC = () => {
  const { t } = useTranslation()

  console.log('Admin')

  return (
    <IonReactRouter>
      <IonTabs>

        <IonRouterOutlet>
          <Route exact path="/admin_index" component={AdminIndex} />
          <Route exact path="/admin_users" component={Users} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/admin_messages" component={Messages} />
          <Route exact path="/admin">
            <Redirect to="/admin_users" />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
            <IonTabButton
              tab="admin_users"
              href="/admin_users"
              style={{ marginRight: 50 }}
            >
              <IonIcon icon={people} />
              <IonLabel>{t('title.users')}</IonLabel>
            </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={person} />
            <IonLabel>{t('profile')}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <IonFab vertical="bottom" horizontal="center" style={{ width: 100 }}>
        <IonFabButton>
          <IonImg className='small-logo' src="/assets/icon/small-logo.png"></IonImg>
        </IonFabButton>
      </IonFab>
    </IonReactRouter>
  )
}

export default Admin
