import axios, { AxiosError } from 'axios'
import { useMutation, UseMutationResult, useQuery } from 'react-query'
import { BASE_URL } from '../types/constants'
import { ErrorResponse, SuccessResponse } from '../types/interfaces'

type CreateGlucoseInput = {
  measurement: number
  date: string
  fast: boolean
}

type KnownError = AxiosError<ErrorResponse>

const createGlucose = async (
  input: CreateGlucoseInput
): Promise<SuccessResponse> => {
  const response = await axios.post<SuccessResponse>(
    `${BASE_URL}/v2/glucose`,
    input,
    {
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
    }
  )
  return response.data
}

export const useCreateGlucose = (): UseMutationResult<
  SuccessResponse,
  KnownError,
  CreateGlucoseInput
> => {
  return useMutation<SuccessResponse, KnownError, CreateGlucoseInput>(
    createGlucose
  )
}

const fetchGlucoseRecords = async (limit: number, offset = 0) => {
  const response = await axios.get(`${BASE_URL}/v2/glucose`, {
    params: { limit, offset },
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
  })
  return response.data
}

export const useGlucoseRecords = (limit: number, offset = 0) => {
  return useQuery(['glucoseRecords', { limit, offset }], () =>
    fetchGlucoseRecords(limit, offset)
  )
}

const deleteGlucose = async (id: string): Promise<SuccessResponse> => {
  const response = await axios.delete<SuccessResponse>(
    `${BASE_URL}/v2/glucose/${id}`,
    {
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
    }
  )
  return response.data
}

export const useDeleteGlucose = (): UseMutationResult<
  SuccessResponse,
  KnownError,
  string
> => {
  return useMutation<SuccessResponse, KnownError, string>(deleteGlucose)
}
