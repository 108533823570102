import './CountableItem.css'
import {
  IonItem,
  IonLabel,
  IonThumbnail
} from '@ionic/react'
import { t } from 'i18next'
import PlusMinusNumber from './PlusMinusNumber'

interface CountableItemProps {
  title:string, measure?:string, itemVar:number, icon:any|null, setItemVarFunction:Function, step?:number
}

export const CountableItem: React.FC<CountableItemProps> = ({ title, measure, itemVar, icon, setItemVarFunction, step = 1 }) => {
  return <IonItem>
  {icon
    ? <IonThumbnail slot="start">
    <span className="item-icon">{icon}</span>
  </IonThumbnail>
    : null}
  <IonLabel slot={icon ? '' : 'start'}>
    <h2>
      <b>{t(title)}</b>
    </h2>
    <p>{measure}</p>
  </IonLabel>
  <div slot="end">
    <PlusMinusNumber
      itemVar={itemVar}
      setItemVarFunction={setItemVarFunction}
      step={step}
    />
  </div>
</IonItem>
}

export default CountableItem
