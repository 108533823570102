import './CountableItem.css'
import { IonIcon } from '@ionic/react'
import { removeOutline, addOutline } from 'ionicons/icons'

interface CountableItemProps {
  itemVar:number, setItemVarFunction:Function, step?: number
}

export const PlusMinusNumber: React.FC<CountableItemProps> = ({ itemVar, setItemVarFunction, step = 1 }) => {
  return <div className="item-counter">
    <IonIcon className="item-minus"
      icon={removeOutline}
      onClick={() => {
        if (itemVar > 0) setItemVarFunction(itemVar - step)
      }}
    />
    <span>{itemVar}</span>
    <IonIcon className="item-plus"
      icon={addOutline}
      onClick={() => {
        setItemVarFunction(itemVar + step)
      }}
    />
  </div>
}

export default PlusMinusNumber
