import { useQuery } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react'
import { t } from 'i18next'
import { chevronBack } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserSelected } from '../../redux/actions'
import { USER_RECORDS } from '../common/DbConstants'
import { Filters } from '../common/records/Filters'
import { Records } from '../common/Records'
import SkeletonItems from '../common/SkeletonItems'

export const UserRecords: React.FC = () => {
  const dispatch = useDispatch()
  const currentUserId = useSelector((state: any) => state.userSelected.id)
  const currentUserName = useSelector((state: any) => state.userSelected.name)
  const [items, setItems] = useState([])

  const { loading, error, data, refetch } = useQuery(USER_RECORDS, {
    variables: { userId: currentUserId }
  })

  useEffect(() => {
    if (data) {
      setItems(data.userRecords)
    }
  }, [data])

  useIonViewWillEnter(() => {
    refetch()
  })

  if (loading) return <SkeletonItems />
  if (error) return <div>{error}</div>

  const handleDismiss = () => {
    dispatch(setUserSelected({}))
  }

  return <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonButton onClick={handleDismiss}>
            <IonIcon icon={chevronBack} onClick={handleDismiss} />
          </IonButton>
        </IonButtons>
        <IonTitle>
          {t('title.user_records', {
            name: currentUserName
          })}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <Filters data={data.userRecords} setItems={setItems} />
      <Records items={items} refetch={refetch} />
    </IonContent>
  </IonPage>
}
