import './Sugar.css'
import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { chevronBack, closeOutline, warningOutline } from 'ionicons/icons'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { RECORD_INSERT, addRecordType, ITEM_SUGAR, REASON_IRRELEVANT } from '../common/DbConstants'
import MoodSelector from '../common/MoodSelector'
import ReasonSelector from '../common/ReasonSelector'
import { GoalNotification } from '../../types/NotificationTypes'
import { setMotivationalQuoteIndex } from '../../redux/actions'
import { motivationalQuotes, MOTIVATIONAL_QUOTES_LENGTH } from '../common/Constants'
import GoalToast from '../common/GoalToast'
import RecordDate from '../common/RecordDate'

export const Sugar: React.FC = () => {
  const [key, setKey] = useState(0)
  const [description, setDescription] = useState('')
  const [unit, setUnit] = useState<null | number>(null)
  const mood = useSelector((state: any) => state.moodSelected)
  const reason = useSelector((state: any) => state.reasonSelected)
  const motivationalQuoteIndex = useSelector((state: any) => state.motivationalQuoteIndex)
  const [toastMsg, setToastMsg] = useState('')
  const [toastMotivationalMsg, setToastMotivationalMsg] = useState('')
  const [goalMsg, setGoalMsg] = useState('')
  const [goalHeader, setGoalHeader] = useState('')
  const [recordDate, setRecordDate] = useState(new Date().toISOString())
  const dispatch = useDispatch()

  const history = useHistory()
  const [createRecord] = useMutation(RECORD_INSERT)

  const reset = () => {
    setDescription('')
    setUnit(null)
    setKey(key + 1)
    setRecordDate(new Date().toISOString())
  }

  const addRecord = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (description === '') {
      setToastMsg(t('message.description_required'))
      return
    } else if (!reason) {
      setToastMsg(t('message.reason_required'))
      return
    } else if (!mood) {
      setToastMsg(t('message.mood_required'))
      return
    }

    const response = await addRecordType(createRecord, ITEM_SUGAR, null, parseInt(reason), parseInt(mood), unit || 0, description, recordDate)
    const goalNotification: GoalNotification = response?.data?.addRecord?.goalNotification

    if (goalNotification) {
      setGoalHeader(goalNotification.header)
      setGoalMsg(goalNotification.message)
    }

    if (parseInt(reason) === REASON_IRRELEVANT) {
      setToastMotivationalMsg(t(motivationalQuotes[motivationalQuoteIndex]))
      dispatch(setMotivationalQuoteIndex((motivationalQuoteIndex + 1) % MOTIVATIONAL_QUOTES_LENGTH))
    }

    reset()
    history.push('/actions')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.sugar')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent key={key}>
        <RecordDate recordDate={recordDate} setRecordDate={setRecordDate} />
        <div className="ion-padding-top" />
        <IonItem>
          <IonInput
            required
            placeholder={t('placeholder.description')}
            type="text"
            onInput={(e: any) => {
              setDescription(e.target.value)
            }}
            value={description}
          />
        </IonItem>
        <IonItem>
          <IonInput
            required
            placeholder={t('placeholder.units')}
            type="number"
            onInput={(e: any) => {
              setUnit(parseInt(e.target.value))
            }}
            value={unit}
          />
        </IonItem>
        <ReasonSelector />
        <MoodSelector />
        <IonButton className="ion-padding" expand="block" onClick={addRecord}>
          {t('save')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg('')}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />

      <IonToast
        isOpen={!!toastMotivationalMsg}
        onDidDismiss={() => setToastMotivationalMsg('')}
        message={toastMotivationalMsg || ''}
        duration={5000}
        position={'middle'}
        color={'white'}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel'
          }
        ]}
        cssClass={'motivational-toast'}
      />

      <GoalToast
        header={goalHeader}
        message={goalMsg}
        setHeader={setGoalHeader}
        setMessage={setGoalMsg}
        icon={warningOutline}
      />
    </IonPage>
  )
}

export default Sugar
