import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonText
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { WaterNotifications } from './WaterNotifications'
import { ExerciseNotifications } from './ExerciseNotifications'
import { ReminderNotifications } from './ReminderNotifications'

export const NotificationsMenu: React.FC = () => {
  const { t } = useTranslation()
  const [notificationSelection, setNotificationSelection] = useState('1')

  const WATER_SEGMENT = '1'
  const EXERCISE_SEGMENT = '2'
  const REMINDER_SEGMENT = '3'

  return (
    <div>
      <IonAccordionGroup className="ion-margin-top">
        <IonAccordion value="water-notifications">
          <IonItem slot="header">
            <IonLabel className="ion-padding-horizontal">
              {t('notifications')}
            </IonLabel>
          </IonItem>

          <IonList slot="content">
            <IonSegment
              onIonChange={(e) =>
                setNotificationSelection(e.detail.value || '1')
              }
              value={notificationSelection}
            >
              <IonSegmentButton value={WATER_SEGMENT}>
                <IonText>{t('action.water')}</IonText>
              </IonSegmentButton>

              <IonSegmentButton value={EXERCISE_SEGMENT}>
                <IonText>{t('action.exercise')}</IonText>
              </IonSegmentButton>

              <IonSegmentButton value={REMINDER_SEGMENT}>
                <IonText>{t('notification.alcohol_sugar')}</IonText>
              </IonSegmentButton>
            </IonSegment>

            {notificationSelection === WATER_SEGMENT && (
              <WaterNotifications />
            )}

            {notificationSelection === EXERCISE_SEGMENT && (
              <ExerciseNotifications />
            )}

            {notificationSelection === REMINDER_SEGMENT && (
              <ReminderNotifications />
            )}
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  )
}
