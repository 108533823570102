import './Records.css'
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react'
import {
  SORTABLE_DATE_FORMAT
} from '../common/DbConstants'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
import RecordItem from './records/RecordItem'

interface RecordsProps {
  items: any,
  refetch: any
}

const recordSortableDate = (record: any) =>
  moment.unix(record.createdAt / 1000).format(SORTABLE_DATE_FORMAT)

export const Records: React.FC<RecordsProps> = ({ items, refetch }) => {
  const userData = useSelector((state: any) => state.userData)

  const dates = items.map((r: { createdAt: any }) =>
    recordSortableDate(r)
  )

  const uniqueDates = Array.from(new Set<any>(dates)).sort().reverse()

  const accordions = uniqueDates.map((d: string) => (
    <IonAccordion key={d}>
      <IonItem slot="header">
        <IonLabel>
          {moment(d).format(process.env.REACT_APP_DATE_FORMAT)}
        </IonLabel>
      </IonItem>
      <IonList slot="content">
        {items
          .filter((r: any) => recordSortableDate(r) === d)
          .map((r: any) => (
            <RecordItem key={r.id} r={r} userData={userData} refetch={refetch} />
          ))
          .sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1))}
      </IonList>
    </IonAccordion>
  ))

  return (
    <Fragment>
      <IonAccordionGroup multiple={true} value={uniqueDates[0]} expand="inset">
        {accordions}
      </IonAccordionGroup>
    </Fragment>
  )
}
