import { format, parse } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import {
  ConfigDataExercise,
  ConfigDataReminder,
  ConfigDataWater
} from '../../types/NotificationTypes'

const optionsUTC: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hourCycle: 'h23', timeZone: 'UTC' }
const optionsTimeZoned: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hourCycle: 'h23', timeZone: process.env.REACT_APP_TIME_ZONE }

export const getUtcConfigData = (configData: ConfigDataWater[] | ConfigDataReminder[]) => {
  const configDataCopy = JSON.parse(JSON.stringify(configData))
  return configDataCopy.map((data: ConfigDataWater | ConfigDataReminder) => {
    data.initTime = new Intl.DateTimeFormat('en-US', optionsUTC).format(parse(data.initTime, 'HH:mm', new Date()))
    data.offTime = new Intl.DateTimeFormat('en-US', optionsUTC).format(parse(data.offTime, 'HH:mm', new Date()))
    return data
  })
}

export const getZonedConfigData = (myNotification: ConfigDataWater[] | ConfigDataReminder[]) => {
  const configDataCopy = JSON.parse(JSON.stringify(myNotification))
  return configDataCopy.map((data: ConfigDataWater | ConfigDataReminder) => {
    const { 0: initTimeHour, 1: initTimeMin } = data.initTime.split(':')
    const { 0: offTimeHour, 1: offTimeMin } = data.offTime.split(':')
    data.initTime = new Intl.DateTimeFormat('en-US', optionsTimeZoned).format(new Date(1970, 1, 1, parseInt(initTimeHour), parseInt(initTimeMin)))
    data.offTime = new Intl.DateTimeFormat('en-US', optionsTimeZoned).format(new Date(1970, 1, 1, parseInt(offTimeHour), parseInt(offTimeMin)))
    return data
  })
}

export const getUtcConfigDataExercise = (configDataExercise: ConfigDataExercise[]) => {
  const configDataExerciseCopy = JSON.parse(JSON.stringify(configDataExercise))
  return configDataExerciseCopy.map((data: ConfigDataExercise) => {
    data.time = new Intl.DateTimeFormat('en-US', optionsUTC).format(parse(data.time, 'HH:mm', new Date()))
    return data
  })
}

export const getZonedConfigDataExercise = (myNotification: ConfigDataExercise[]) => {
  const configDataCopy = JSON.parse(JSON.stringify(myNotification))
  return configDataCopy.map((data: ConfigDataExercise) => {
    const { 0: hour, 1: min } = data.time.split(':')
    data.time = new Intl.DateTimeFormat('en-US', optionsTimeZoned).format(new Date(1970, 1, 1, parseInt(hour), parseInt(min)))
    return data
  })
}

/**
 * Convert a given local time string to its corresponding UTC time string.
 *
 * @param {string} time - The time string in HH:MM format representing local time.
 * @returns {string | null} The time string in HH:MM format representing UTC time, or null if the input format is invalid.
 * @example
 *
 * convertTimeToUTC("12:30");  // Could return "17:30" depending on your local time zone.
 * convertTimeToUTC("25:30");  // Returns null due to invalid format.
 */
export function convertTimeToUTC(time: string): string | null {
  // Validate input format
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
  if (!timeRegex.test(time)) {
    console.error('Invalid time format. Expected HH:MM')
    return null
  }

  // Extract hours and minutes from the input string
  const [inputHours, inputMinutes] = time.split(':').map(Number)

  // Create a new Date object and set the local time
  const currentDate = new Date()
  currentDate.setHours(inputHours, inputMinutes, 0, 0)

  // Convert to UTC time and extract the hours and minutes
  const isoString = currentDate.toISOString()
  const [utcHours, utcMinutes] = isoString.split('T')[1].split(':').map(Number)

  // Format the result
  return `${utcHours.toString().padStart(2, '0')}:${utcMinutes
    .toString()
    .padStart(2, '0')}`
}

/**
 * Convert a UTC time (HH:mm) to its local time zone equivalent.
 * Assumes that the user's local time zone should be used for the conversion.
 * @param {string} time - The UTC time in HH:mm format.
 * @returns {string} - The time converted to the user's local time zone in HH:mm format.
 */
export function convertTimeToLocal(time: string): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone // Get the user's time zone
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const currentDay = currentDate.getDate().toString().padStart(2, '0')

  const utcDateWithTime = new Date(
    `${currentYear}-${currentMonth}-${currentDay}T${time}Z`
  )

  const dateInLocalTimeZone = utcToZonedTime(utcDateWithTime, timeZone)

  return format(dateInLocalTimeZone, 'HH:mm')
}
