import './AddFollowUp.css'
import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { FOLLOW_UP_INSERT } from './DbConstants'

export const AddFollowUp: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [createFollowUp] = useMutation(FOLLOW_UP_INSERT)
  const [weight, setWeight] = useState('')
  const [muscleMass, setMuscleMass] = useState('')
  const [waterPercentage, setWaterPercentage] = useState('')
  const [viscelarFat, setViscelarFat] = useState('')
  const [appointmentDate, setApointmentDate] = useState(
    moment().format('YYYY-MM-DD')
  )
  const [toastMsg, setToastMsg] = useState(null)

  const reset = () => {
    setWeight('')
    setMuscleMass('')
    setWaterPercentage('')
    setViscelarFat('')
  }

  const goToFollowUp = () => {
    reset()
    history.push('/action/follow_up')
  }

  const addFollowUp = async () => {
    if (
      moment(appointmentDate).format('YYYY-MM-DD') >
      moment().format('YYYY-MM-DD')
    ) {
      setToastMsg(t('message.appointment_date_in_future'))
      return
    }

    if (!weight && !muscleMass && !waterPercentage && !viscelarFat) {
      setToastMsg(t('message.at_least_one_value_required'))
      return
    }

    await createFollowUp({
      variables: {
        appointmentDate,
        weight,
        muscleMass,
        waterPercentage,
        viscelarFat
      }
    })

    goToFollowUp()
  }

  const inputParam = (param: string, setFunc: Function) => (
    <IonInput
      type="number"
      placeholder="0.00"
      onInput={(e: any) => {
        if (isNaN(parseFloat(e.target.value)) || e.target.value > 999.99) {
          setFunc('')
        } else {
          setFunc(e.target.value)
        }
      }}
      value={param}
    />
  )

  return (
    <IonPage className="input-form add-follow-up">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={goToFollowUp}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.new_follow_up')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList
          lines="full"
          class="ion-no-margin ion-no-padding"
          style={{
            borderRadius: '5px'
          }}
        >
          <IonItem button={true} id="open-date-input">
            <IonLabel position="stacked">
              {t('field.appointment_date')}
            </IonLabel>
            <IonText>
              {moment(appointmentDate).format(
                process.env.REACT_APP_DATE_FORMAT
              )}
            </IonText>
            <IonPopover
              trigger="open-date-input"
              showBackdrop={false}
              side="bottom"
              alignment="start"
            >
              <IonDatetime
                presentation="date"
                onIonChange={(ev) =>
                  setApointmentDate(ev.detail.value! as string)
                }
              />
            </IonPopover>
          </IonItem>
          <IonItem>
            <IonLabel slot="start">{t('field.weight')}</IonLabel>
            {inputParam(weight, setWeight)}
          </IonItem>
          <IonItem>
            <IonLabel slot="start">{t('field.muscle_mass')}</IonLabel>
            {inputParam(muscleMass, setMuscleMass)}
          </IonItem>
          <IonItem>
            <IonLabel slot="start">{t('field.fat_percentage')}</IonLabel>
            {inputParam(waterPercentage, setWaterPercentage)}
          </IonItem>
          <IonItem>
            <IonLabel slot="start">{t('field.viscelar_fat')}</IonLabel>
            {inputParam(viscelarFat, setViscelarFat)}
          </IonItem>
        </IonList>

        <IonButton
          expand="block"
          onClick={addFollowUp}
          className="ion-margin-top"
        >
          {t('action.add_follow_up')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg(null)}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />
    </IonPage>
  )
}

export default AddFollowUp
