const defaultState = {
  user: {},
  userData: {},
  moodSelected: null,
  reasonSelected: null,
  userSelected: {},
  motivationalQuoteIndex: 0,
  viewMessages: false,
  waterNotification: [{
    initTime: '09:00',
    offTime: '12:00',
    frequency: 1,
    active: false
  }],
  exerciseNotification: [
    { day: 'monday', time: '00:00', active: false },
    { day: 'tuesday', time: '00:00', active: false },
    { day: 'wednesday', time: '00:00', active: false },
    { day: 'thursday', time: '00:00', active: false },
    { day: 'friday', time: '00:00', active: false },
    { day: 'saturday', time: '00:00', active: false },
    { day: 'sunday', time: '00:00', active: false }
  ],
  reminderNotification: [{
    initTime: '09:00',
    offTime: '12:00',
    frequency: 1,
    active: false
  }]
}

export default function reducer (
  state = defaultState,
  { type, payload }: { type: string; payload: any }
): any {
  switch (type) {
    case 'SET_USER_STATE':
      return {
        ...state,
        user: payload
      }
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: payload
      }
    case 'SET_MOOD_SELECTED':
      return {
        ...state,
        moodSelected: payload
      }
    case 'SET_REASON_SELECTED':
      return {
        ...state,
        reasonSelected: payload
      }
    case 'SET_USER_SELECTED':
      return {
        ...state,
        userSelected: payload
      }
    case 'SET_MOTIVATIONAL_QUOTE_INDEX':
      return {
        ...state,
        motivationalQuoteIndex: payload
      }
    case 'SET_VIEW_MESSAGES':
      return {
        ...state,
        viewMessages: payload
      }
    case 'SET_WATER_NOTIFICATION':
      return {
        ...state,
        waterNotification: payload
      }
    case 'SET_EXERCISE_NOTIFICATION':
      return {
        ...state,
        exerciseNotification: payload
      }
    case 'SET_REMINDER_NOTIFICATION':
      return {
        ...state,
        reminderNotification: payload
      }
  }

  return state
}
