import { IonIcon } from '@ionic/react'
import { waterOutline, wineOutline, cellularOutline, iceCreamOutline, barbell, analyticsOutline } from 'ionicons/icons'
import { ITEM_WATER, ITEM_ALCOHOL, ITEM_HUNGER_SCALE, ITEM_SUGAR, ITEM_EXERCISE, ITEM_HISTAMINE } from '../DbConstants'

const ItemIcon = (record: any) => {
  switch (record.itemId) {
    case ITEM_WATER:
      return <IonIcon icon={waterOutline} />
    case ITEM_ALCOHOL:
      return <IonIcon icon={wineOutline} />
    case ITEM_HUNGER_SCALE:
      return <IonIcon icon={cellularOutline} />
    case ITEM_SUGAR:
      return <IonIcon icon={iceCreamOutline} />
    case ITEM_EXERCISE:
      return <IonIcon icon={barbell} />
    case ITEM_HISTAMINE:
      return <IonIcon icon={analyticsOutline} />
  }
}

export default ItemIcon
