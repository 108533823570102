import './MyGoals.css'
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPicker,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  PickerColumn,
  useIonViewWillEnter
} from '@ionic/react'
import { t } from 'i18next'
import { useState } from 'react'
import {
  REASON_EVENT,
  REASON_IRRELEVANT,
  REASON_CHALLENGE,
  EXERCISE_TYPE_WALKING,
  EXERCISE_TYPE_BIKING,
  EXERCISE_TYPE_GYM,
  EXERCISE_TYPE_RUNNING,
  EXERCISE_TYPE_SWIMMING,
  MEASURE_DURATION_MINUTES,
  GOALS_INSERT,
  MY_GOALS,
  MEASURE_DURATION_REPEATS,
  EXERCISE_TYPE_CUSTOM,
  EXERCISE_TYPE_CONTACT_SPORT,
  EXERCISE_TYPE_TEAM_SPORT,
  GOAL_WATER_LITRES,
  GOAL_ALCOHOL,
  GOAL_EXERCISE,
  GOAL_SUGAR,
  GOAL_PHYSICAL
} from '../common/DbConstants'
import PlusMinusNumber from '../common/PlusMinusNumber'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { add, chevronBack, close } from 'ionicons/icons'
import { format, add as addDate } from 'date-fns'

export const MyGoals: React.FC = () => {
  const [waterGoal, setWaterGoal] = useState({
    waterLitres: 0,
    goalId: GOAL_WATER_LITRES
  })
  const [alcoholGoal, setAlcoholGoal] = useState({
    daysWithoutAlcohol: 0,
    alchoholSelection: '',
    goalId: GOAL_ALCOHOL,
    startDate: new Date()
  })
  const [sugarGoal, setSugarGoal] = useState({
    daysWithoutSugar: 0,
    sugarSelection: '',
    goalId: GOAL_SUGAR,
    startDate: new Date()
  })

  const [daysWithoutAlcohol, setDaysWithoutAlcohol] = useState(0)
  const [alchoholSelection, setAlcoholSelection] = useState('')
  const [sugarSelection, setSugarSelection] = useState('')
  const [pickerOpen, setPickerOpen] = useState(false)
  const [daysWithoutSugar, setDaysWithoutSugar] = useState(0)
  const [exercises, setExercises] = useState<any[]>([])
  const [weight, setWeight] = useState('')
  const [muscleMass, setMuscleMass] = useState('')
  const [waterPercentage, setWaterPercentage] = useState('')
  const [viscelarFat, setViscelarFat] = useState('')

  const history = useHistory()

  const [getGoal] = useLazyQuery(MY_GOALS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const myGoals = data?.myGoals ?? null

      if (myGoals) {
        myGoals.forEach((goal: any) => {
          const goalData = JSON.parse(goal.data)

          switch (goal.goalId) {
            case GOAL_WATER_LITRES:
              setWaterGoal({
                waterLitres: goalData.waterLitres,
                goalId: GOAL_WATER_LITRES
              })
              break
            case GOAL_ALCOHOL:
              setDaysWithoutAlcohol(goalData.daysWithoutAlcohol || 0)
              setAlcoholSelection(goalData.alchoholSelection || 0)
              setAlcoholGoal({
                daysWithoutAlcohol: goalData.daysWithoutAlcohol,
                alchoholSelection: goalData.alchoholSelection,
                goalId: GOAL_ALCOHOL,
                startDate: new Date(parseInt(goal.startDate))
              })
              break
            case GOAL_SUGAR:
              setDaysWithoutSugar(goalData.daysWithoutSugar || 0)
              setSugarSelection(goalData.sugarSelection || 0)
              setSugarGoal({
                daysWithoutSugar: goalData.daysWithoutSugar,
                sugarSelection: goalData.sugarSelection,
                goalId: GOAL_SUGAR,
                startDate: new Date(parseInt(goal.startDate))
              })
              break
            case GOAL_EXERCISE:
              setExercises(goalData || 0)
              break
            case GOAL_PHYSICAL:
              setWeight(goalData?.weight || 0)
              setMuscleMass(goalData?.muscleMass || 0)
              setViscelarFat(goalData?.viscelarFat || 0)
              setWaterPercentage(goalData?.waterPercentage || 0)
              break
            default:
              break
          }
        })
      }
    }
  })

  useIonViewWillEnter(async () => {
    await getGoal()
  })

  const [createGoals] = useMutation(GOALS_INSERT)

  const saveGoals = async () => {
    console.log('saveGoals')

    const goals: any = {
      waterGoal,
      alcoholGoal,
      sugarGoal,
      exercises: {
        goalId: GOAL_EXERCISE,
        exercises
      },
      physicalData: {
        goalId: GOAL_PHYSICAL,
        weight,
        muscleMass,
        waterPercentage,
        viscelarFat
      }
    }

    const data = JSON.stringify(goals)

    await createGoals({ variables: { data } })

    history.push('/actions')
  }

  const handleAddExercise = (params: any) => {
    exercises.push({
      exercise: params.exercise.value,
      exerciseText: params.exercise.value !== EXERCISE_TYPE_CUSTOM && params.exercise.text,
      quantity: params.quantity.value,
      duration: params.duration.value
    })

    setExercises(exercises)
    setPickerOpen(false)
  }

  const removeExercise = (index: number) => {
    const newExercises = exercises.filter((_, i) => i !== index)
    setExercises(newExercises)
  }

  const handleWaterGoal = (waterLitres: number) => {
    setWaterGoal({
      ...waterGoal,
      waterLitres
    })
  }

  const handleAlcoholSelection = (alchoholSelection: string) => {
    setAlcoholSelection(alchoholSelection)
    setAlcoholGoal({
      ...alcoholGoal,
      alchoholSelection,
      startDate: new Date()
    })
  }

  const handleDaysWithoutAlcohol = (daysWithoutAlcohol: number) => {
    setDaysWithoutAlcohol(daysWithoutAlcohol)
    setAlcoholGoal({
      ...alcoholGoal,
      daysWithoutAlcohol,
      startDate: new Date()
    })
  }

  const handleSugarSelection = (sugarSelection: string) => {
    setSugarSelection(sugarSelection)
    setSugarGoal({
      ...sugarGoal,
      sugarSelection,
      startDate: new Date()
    })
  }

  const handleDaysWithoutSugar = (daysWithoutSugar: number) => {
    setDaysWithoutSugar(daysWithoutSugar)
    setSugarGoal({
      ...sugarGoal,
      daysWithoutSugar,
      startDate: new Date()
    })
  }

  const goalDates = (goal: any, days: number) => {
    const start = format(goal.startDate, 'dd-MM')
    const end = format(addDate(goal.startDate, { days }), 'dd-MM')

    return (
      <div slot="end" className='goal-dates'>
        <IonLabel><p>Inicio: {start.toString()}</p></IonLabel>
        <IonLabel><p>Fin: {end.toString()}</p></IonLabel>
      </div>
    )
  }

  const ExerciseColumn = {
    name: 'exercise',
    columnWidth: '65%',
    align: '',
    options: [
      { text: t('exercise.walking'), value: EXERCISE_TYPE_WALKING },
      { text: t('exercise.running'), value: EXERCISE_TYPE_RUNNING },
      { text: t('exercise.biking'), value: EXERCISE_TYPE_BIKING },
      { text: t('exercise.swimming'), value: EXERCISE_TYPE_SWIMMING },
      { text: t('exercise.gym'), value: EXERCISE_TYPE_GYM },
      { text: t('exercise.contact_sport'), value: EXERCISE_TYPE_CONTACT_SPORT },
      { text: t('exercise.team_sport'), value: EXERCISE_TYPE_TEAM_SPORT },
      { text: t('exercise.custom'), value: EXERCISE_TYPE_CUSTOM }
    ]
  } as PickerColumn

  const QuantityColumn = {
    name: 'quantity',
    columnWidth: '10%',
    options: [...Array(19)].map((e, i) => (
      { text: (i === 0 ? 1 : i * 5).toString(), value: (i === 0 ? 1 : i * 5) }
    ))
  } as PickerColumn

  const DurationColumn = {
    name: 'duration',
    columnWidth: '30%',
    options: [
      { text: t('duration.minutes'), value: MEASURE_DURATION_MINUTES },
      { text: t('duration.repeats'), value: MEASURE_DURATION_REPEATS }
    ]
  } as PickerColumn

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('title.my_goals')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonAccordionGroup multiple={true}>
          <IonAccordion value="water">
            <IonItem slot="header">
              <h3>{t('action.water')}</h3>
            </IonItem>
            <IonList slot="content">
              <IonItem>
                <IonLabel slot='start'>{t('my_goals.water.litres_per_day')}</IonLabel>
                <div slot='end'>
                  <PlusMinusNumber
                    itemVar={waterGoal.waterLitres}
                    setItemVarFunction={handleWaterGoal}
                    step={0.5}
                  />
                </div>
              </IonItem>
            </IonList>
          </IonAccordion>
          <IonAccordion value="alcohol">
            <IonItem slot="header">
              <h3>{t('action.alcohol')}</h3>
            </IonItem>
            <IonList slot="content">
              <IonSegment
                onIonChange={(e) => {
                  handleAlcoholSelection(e.detail.value || '')
                }}
                value={alchoholSelection}
              >
                <IonSegmentButton value={REASON_IRRELEVANT.toString()}>
                  <IonLabel>{t('my_goals.alcohol.days_without')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={REASON_EVENT.toString()}>
                  <IonLabel>{t('my_goals.alcohol.only_events')}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {alchoholSelection === REASON_IRRELEVANT.toString() && (
                <IonItem slot="start">
                  <PlusMinusNumber
                    itemVar={daysWithoutAlcohol}
                    setItemVarFunction={handleDaysWithoutAlcohol}
                  />
                  {
                    goalDates(alcoholGoal, daysWithoutAlcohol)
                  }
                </IonItem>
              )}
            </IonList>
          </IonAccordion>
          <IonAccordion value="sugar">
            <IonItem slot="header">
              <h3>{t('action.sugar')}</h3>
            </IonItem>
            <IonList slot="content">
              <IonSegment
                onIonChange={(e) => handleSugarSelection(e.detail.value || '')}
                value={sugarSelection}
              >
                <IonSegmentButton value={REASON_IRRELEVANT.toString()}>
                  <IonLabel>{t('my_goals.sugar.days_without')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={REASON_CHALLENGE.toString()}>
                  <IonLabel>{t('my_goals.sugar.challenge_21_days')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={REASON_EVENT.toString()}>
                  <IonLabel>{t('my_goals.sugar.only_events')}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {sugarSelection === REASON_IRRELEVANT.toString() && (
                <IonItem slot="start">
                  <PlusMinusNumber
                    itemVar={daysWithoutSugar}
                    setItemVarFunction={handleDaysWithoutSugar}
                  />
                  {
                    goalDates(sugarGoal, daysWithoutSugar)
                  }
                </IonItem>
              )}
              {sugarSelection === REASON_CHALLENGE.toString() && (
                <IonItem slot="start">
                {
                  goalDates(sugarGoal, 21)
                }
                </IonItem>
              )}
            </IonList>
          </IonAccordion>

          <IonAccordion value="exercise">
            <IonItem slot="header">
              <h3>{t('action.exercise')}</h3>
            </IonItem>
            <IonList className="exercise-goals" slot="content" key={exercises.length}>
              {exercises.map((e: any, index: number) => {
                return (
                  <IonItem slot='start' className="exercise-goals-item" key={index}>
                    {
                      e.exercise === EXERCISE_TYPE_CUSTOM
                        ? <IonItem className='exercise-custom-input'>
                          <IonInput
                            placeholder={t('exercise.custom')}
                            type="text"
                            value={e.exerciseText}
                            onInput={(e: any) => {
                              exercises[index].exerciseText = e.target.value
                              setExercises(exercises)
                            }}
                          />
                        </IonItem>
                        : <IonLabel slot="start">{exercises[index].exerciseText}</IonLabel>
                    }

                    <div slot="end" className='exercise-info'>{e.quantity} <span className='exercise-duration'>{DurationColumn.options.find((d: any) => d.value === e.duration)?.text}</span></div >

                    <IonButton strong slot='end' size='small' color="danger" shape='round' onClick={() => { removeExercise(index) }}>
                      <IonIcon icon={close}></IonIcon>
                    </IonButton>
                  </IonItem>
                )
              })}

              <IonButton strong onClick={() => setPickerOpen(true)}>
                <IonIcon icon={add}></IonIcon>
              </IonButton>

              <IonPicker
                isOpen={pickerOpen}
                columns={[ExerciseColumn, QuantityColumn, DurationColumn]}
                buttons={[
                  {
                    text: t('cancel'),
                    role: 'cancel',
                    handler: () => {
                      setPickerOpen(false)
                    }
                  },
                  {
                    text: t('confirm'),
                    handler: (value) => {
                      handleAddExercise(value)
                    }
                  }
                ]}
              ></IonPicker>
            </IonList>
          </IonAccordion>

          <IonAccordion value="physical">
            <IonItem slot="header">
              <h3>{t('field.physical_data')}</h3>
            </IonItem>
            <IonList slot="content" className="physical-data">
              <IonItem>
                <IonLabel slot="start">{t('field.weight')}</IonLabel>
                <IonInput
                  placeholder={t('placeholder.physical_data')}
                  type="number"
                  value={weight}
                  onInput={(e: any) => { setWeight(e.target.value) }}
                />
              </IonItem>
              <IonItem>
                <IonLabel slot="start">{t('field.muscle_mass')}</IonLabel>
                <IonInput
                  placeholder={t('placeholder.physical_data')}
                  type="number"
                  value={muscleMass}
                  onInput={(e: any) => { setMuscleMass(e.target.value) }}
                />
              </IonItem>
              <IonItem>
                <IonLabel slot="start">{t('field.fat_percentage')}</IonLabel>
                <IonInput
                  placeholder={t('placeholder.physical_data')}
                  type="number"
                  value={waterPercentage}
                  onInput={(e: any) => { setWaterPercentage(e.target.value) }}
                />
              </IonItem>
              <IonItem>
                <IonLabel slot="start">{t('field.viscelar_fat')}</IonLabel>
                <IonInput
                  placeholder={t('placeholder.physical_data')}
                  type="number"
                  value={viscelarFat}
                  onInput={(e: any) => { setViscelarFat(e.target.value) }}
                />
              </IonItem>
            </IonList>
          </IonAccordion>
        </IonAccordionGroup>

        <IonButton className="ion-padding" expand="block" onClick={saveGoals}>
          {t('save')}
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

export default MyGoals
