import { IonToast } from '@ionic/react'
import { closeOutline, warningOutline } from 'ionicons/icons'
import { GoalToastProps } from '../../types/NotificationTypes'

export const GoalToast: React.FC<GoalToastProps> = ({ header, message, setHeader, setMessage, icon = warningOutline }) => {
  return (
        <IonToast
            isOpen={!!header && !!message}
            onDidDismiss={() => {
              setHeader('')
              setMessage('')
            }}
            icon={icon}
            message={message || ''}
            header={header || ''}
            duration={10000}
            position={'top'}
            color={'light'}
            buttons={[
              {
                icon: closeOutline,
                role: 'cancel'
              }
            ]}
        />
  )
}

export default GoalToast
