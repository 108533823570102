import { IonDatetimeButton, IonModal, IonDatetime, IonThumbnail, IonIcon, IonLabel } from '@ionic/react'
import { calendarOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function RecordDate ({ recordDate, setRecordDate }: { recordDate: string, setRecordDate: (date: string) => void }) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const randomId = Math.random().toString(36).substring(7)

  useEffect(() => {
    try {
      const date = new Date(recordDate)
      if (date.toString() === 'Invalid Date') {
        throw new Error('Error - RecordDate useEffect: Invalid date format: ' + recordDate)
      }
    } catch (err) {
      console.error(err)
    }
  }, [recordDate])

  return (
    <div className="ion-padding-top"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0.8em 1em 0 1em'
      }}
    >
      <span style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }} >
        <IonThumbnail
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <IonIcon icon={calendarOutline} size="large" />
        </IonThumbnail>

        <IonLabel>
          <h2>
            <b>{t('action.record_date')}</b>
          </h2>
        </IonLabel>
      </span>
      <div style={{ minWidth: '200px' }}>
        <IonDatetimeButton datetime={`datetime-${randomId}`}>
          {recordDate}
        </IonDatetimeButton>
        <IonModal isOpen={showModal} keepContentsMounted={true}
          style={{
            zIndex: 9999999,
            '--min-width': '300px',
            '--max-width': '500px'
          }}
          onChange={(ev) => setShowModal(false)}
          showBackdrop={true}
        >
          <IonDatetime id={`datetime-${randomId}`}
            style={{
              zIndex: 9999999,
              '--min-width': '300px',
              '--max-width': '500px',
              color: '#70AE6E'
            }}
            color="primary"
            value={recordDate}
            onIonChange={(ev) => {
              setRecordDate(ev.detail.value as string)
            }}
          ></IonDatetime>
        </IonModal>
      </div>
    </div>
  )
}

export default RecordDate
