import './Users.css'
import { useQuery, useMutation } from '@apollo/client'

import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonThumbnail,
  IonToolbar,
  useIonViewDidEnter,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption
} from '@ionic/react'
import { t } from 'i18next'
import { FaUser } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setUserSelected } from '../../redux/actions'
import { REGULAR_USER, CHANGE_USER_ACTIVE } from '../common/DbConstants'
import SkeletonItems from '../common/SkeletonItems'
import { UserRecords } from './UserRecords'
import ToolbarSearch from '../common/ToolbarSearch'

export const Users: React.FC = () => {
  const [changeUserActive] = useMutation(CHANGE_USER_ACTIVE)
  const dispatch = useDispatch()
  const currentUserId = useSelector((state: any) => state.userSelected.id)
  const { loading, error, data, refetch } = useQuery(REGULAR_USER)

  useIonViewDidEnter(() => {
    refetch()
  })

  if (loading) return <SkeletonItems />
  if (error) return <div>{error}</div>

  const changeActive = async (userId: Number, active: Boolean) => {
    await changeUserActive({ variables: { userId, active } })
  }

  const searchItem = (event: any) => {
    const searchText = event.detail.value.toLowerCase()
    const users = document.querySelectorAll<HTMLElement>('.user-item')
    users.forEach((user) => {
      user?.dataset?.name?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchText.trim())
        ? user.classList.remove('hidden')
        : user.classList.add('hidden')
    })
  }

  const users = data.allRegularUsers.map((u:any) =>
   <IonItemSliding className={`user-item ${u.active ? '' : 'disabled'}`} data-name={u.name} key={u.id} >
   <IonItem onClick={() => dispatch(setUserSelected({ id: u.id, name: u.name }))}>
     <IonThumbnail slot="start"><FaUser /></IonThumbnail>
     <IonLabel><h1>{u.name}</h1><p>{u.email}</p></IonLabel>
   </IonItem>
   <IonItemOptions>
     <IonItemOption color={u.active ? 'danger' : 'primary'} onClick={() => changeActive(u.id, !u.active)}>
       <IonIcon slot="start"/>
       {u.active ? t('disable') : t('enable')}
     </IonItemOption>
   </IonItemOptions>
 </IonItemSliding>
  )

  return (
    (currentUserId)
      ? <UserRecords />
      : <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <ToolbarSearch title={t('title.users')} searchItem={searchItem}/>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <IonList>
        {users}
      </IonList>
    </IonContent>
    </IonPage>
  )
}

export default Users
