import { IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/react'
import { t } from 'i18next'
import { closeOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import RecordDate from '../RecordDate'

const ChangeDateModal = ({ record, showModal, setShowModal, updateRecord }: {
  record: any,
  showModal: boolean,
  setShowModal: (showModal: boolean) => void,
  updateRecord: (record: any) => void
}) => {
  const [recordDate, setRecordDate] = useState<string | undefined>(undefined)

  useEffect(() => {
    try {
      const date = new Date(parseInt(record.createdAt)) // record.createdAt is a timestamp
      if (date.toString() === 'Invalid Date') {
        throw new Error('ChangeDateModal useEffect - Invalid date format: ' + record.createdAt)
      }
      setRecordDate(new Date(parseInt(record.createdAt)).toISOString())
    } catch (err) {
      console.error(err)
      setRecordDate(new Date().toISOString())
    }
  }, [])

  if (!recordDate) {
    return null
  }

  return (
    <IonModal id={`change-date-${record.id}`} isOpen={showModal} trigger="change-date"
      style={{
        zIndex: 9999,
        '--min-width': '300px',
        '--max-width': '500px'
      }}
      onDidDismiss={() => {
        setShowModal(false)
      }}
      initialBreakpoint={0.40}
      breakpoints={[0.40, 0.60, 0.80, 1]}
      showBackdrop={true}
      backdropDismiss={true}
      canDismiss
    >
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('action.change_date')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <RecordDate setRecordDate={setRecordDate} recordDate={recordDate} />
          <IonButton color='primary' type='button'
            style={{
              width: '90%',
              margin: '1em auto'
            }}
            expand="block"
            onClick={() => {
              updateRecord({
                ...record,
                createdAt: recordDate
              })
              setShowModal(false)
            }}
          >
            {t('action.change')}
          </IonButton>
        </IonContent>
      </IonContent>
    </IonModal>
  )
}

export default ChangeDateModal
