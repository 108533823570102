import { useState, useEffect, useRef, useCallback } from 'react'
import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSpinner,
  IonToast,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonDatetime,
  IonDatetimeButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonItemGroup,
  IonCol,
  IonRow,
  IonIcon,
  IonItemSliding,
  IonItemOption,
  IonItemOptions
} from '@ionic/react'
import { chevronBack, closeOutline, trash } from 'ionicons/icons'
import {
  useCreateBloodPressure,
  useBloodPressureRecords,
  useDeleteBloodPressure
} from '../../api/bloodPressure'
import { useHistory } from 'react-router'
import { t } from 'i18next'
import { BloodPressureRecord } from '../../types/interfaces'
import { formatTime } from '../../utils/timeHelpers'

export const BloodPressure: React.FC = () => {
  const history = useHistory()
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [systolic, setSystolic] = useState(0)
  const [diastolic, setDiastolic] = useState(0)
  const [saveText, setSaveText] = useState(<>{t('save')}</>)
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMessage] = useState('')
  const [offset, setOffset] = useState(0)
  const [bpRecords, setBpRecords] = useState<BloodPressureRecord[]>([])
  const [hasMoreItems, setHasMoreItems] = useState(true)

  const modal = useRef<HTMLIonModalElement>(null)

  const createBloodPressure = useCreateBloodPressure()
  const deleteBloodPressure = useDeleteBloodPressure()

  const LIMIT = 20
  const {
    data: newData,
    refetch: refetchMore,
    isFetching
  } = useBloodPressureRecords(LIMIT, offset)

  useEffect(() => {
    if (newData?.data && newData.data.length > 0) {
      const newRecords = [
        ...bpRecords,
        ...newData.data.filter(
          (newRecord: BloodPressureRecord) =>
            !bpRecords.find(
              (oldRecord: BloodPressureRecord) => oldRecord.id === newRecord.id
            )
        )
      ].sort((a: BloodPressureRecord, b: BloodPressureRecord) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })

      setBpRecords(newRecords)
    }
  }, [newData])

  const loadMore = () => {
    if (isFetching) return

    if (newData?.data.length < LIMIT) {
      setHasMoreItems(false)
      return
    }

    refetchMore()
    setOffset((prevOffset) => prevOffset + LIMIT)
  }

  async function saveBloodPressure() {
    setSaveText(<IonSpinner name="lines" />)

    setToastMessage('')
    setShowToast(false)

    try {
      await createBloodPressure.mutateAsync(
        {
          systolic,
          diastolic,
          date
        },
        {
          onSuccess: (data) => {
            setToastMessage(t('action.bp_saved'))
            setShowToast(true)

            setBpRecords([])
            setOffset(0)
            refetchMore()
          },
          onError: (error) => {
            console.error(error)
            setToastMessage(t('error.saving_bp'))
            setShowToast(true)
          }
        }
      )
    } catch (error) {
      setToastMessage(t('error.saving_bp'))
      setShowToast(true)
    } finally {
      setSaveText(<>{t('save')}</>)

      setSystolic(0)
      setDiastolic(0)
      setDate(new Date().toISOString())
      setHasMoreItems(true)
    }
  }

  modal.current?.addEventListener('ionModalDidPresent', () => {
    const firstInput = modal.current?.querySelector('ion-input')
    firstInput?.setFocus()
  })

  const handleSave = useCallback(() => {
    if (!systolic || !diastolic || systolic < 1 || diastolic < 1) {
      setToastMessage(t('error.bp_invalid'))
      setShowToast(true)
      return
    }

    if (!date) {
      setToastMessage(t('error.date_invalid'))
      setShowToast(true)
      return
    }

    modal.current?.dismiss({}, 'save')
    saveBloodPressure()
  }, [systolic, diastolic, date])

  async function deleteBloodPressureRecord(id: string) {
    setToastMessage('')
    setShowToast(false)

    try {
      await deleteBloodPressure.mutateAsync(id, {
        onSuccess: (data) => {
          setToastMessage(t('action.bp_deleted'))
          setShowToast(true)

          setBpRecords([])
          setOffset(0)
          refetchMore()
        },
        onError: (error) => {
          console.error(error)
          setToastMessage(t('error.deleting_bp'))
          setShowToast(true)
        }
      })
    } catch (error) {
      setToastMessage(t('error.deleting_bp'))
      setShowToast(true)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.bp')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButton
          onClick={() => modal.current?.present()}
          expand="block"
          className="ion-margin-bottom"
          id="open-bp-modal"
        >
          {t('action.add_bp')}
        </IonButton>
        <IonModal ref={modal} trigger="open-bp-modal">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => modal.current?.dismiss()}>
                  <IonIcon icon={chevronBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>{t('action.add_bp_2')}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonGrid>
              <IonList
                style={{
                  width: '100%',
                  maxWidth: '500px',
                  borderRadius: '10px'
                }}
              >
                {/* Date input */}
                <IonItem>
                  <IonLabel>{t('date')}</IonLabel>
                  <IonDatetimeButton slot="end" datetime="datetime" />
                </IonItem>
                {/* Systolic input */}
                <IonItem>
                  <IonLabel>
                    {t('action.systolic')}
                    <p style={{ fontSize: '0.8em', color: 'gray' }}>
                      {t('action.bp_unit')}
                    </p>
                  </IonLabel>
                  <IonInput
                    slot="end"
                    type="number"
                    id="systolic"
                    placeholder="0"
                    min={1}
                    onIonChange={(e) =>
                      setSystolic(parseInt(e.detail.value!, 10))
                    }
                    style={{ textAlign: 'right' }}
                  />
                </IonItem>
                {/* Diastolic input */}
                <IonItem>
                  <IonLabel>
                    {t('action.diastolic')}
                    <p style={{ fontSize: '0.8em', color: 'gray' }}>
                      {t('action.bp_unit')}
                    </p>
                  </IonLabel>
                  <IonInput
                    slot="end"
                    type="number"
                    id="diastolic"
                    placeholder="0"
                    min={1}
                    onIonChange={(e) =>
                      setDiastolic(parseInt(e.detail.value!, 10))
                    }
                    style={{ textAlign: 'right' }}
                  />
                </IonItem>
                {/* Save button */}
                <IonButton
                  expand="block"
                  onClick={handleSave}
                  className="ion-padding-start ion-padding-end ion-margin-top"
                  id="save-bp"
                >
                  {saveText}
                </IonButton>
              </IonList>
            </IonGrid>
          </IonContent>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetime"
              showDefaultButtons={true}
              onIonChange={(e) => {
                const newValue = e.detail.value!
                const newDate = new Date(
                  Array.isArray(newValue) ? newValue[0] : newValue
                ).toISOString()
                setDate(newDate)
              }}
            />
          </IonModal>
        </IonModal>
        <hr
          style={{
            color: 'gray',
            backgroundColor: 'gray',
            height: 1,
            width: '100%'
          }}
        />
        <IonList
          className="ion-margin-top"
          style={{
            borderRadius: '5px'
          }}
        >
          <IonItemGroup>
            <IonItemDivider
              color="light"
              style={{
                fontSize: '1em',
                fontWeight: 'bold',
                color: 'gray',
                borderRadius: '4px',
                width: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0.4em auto'
              }}
              className="ion-margin-start ion-margin-end ion-text-center"
            >
              <IonLabel
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {t('title.glucose_records')}
              </IonLabel>
            </IonItemDivider>
            {bpRecords.length === 0 && (
              <IonItem>
                <IonLabel>{t('no_data')}</IonLabel>
              </IonItem>
            )}

            {bpRecords.length !== 0 &&
              bpRecords?.map((record: BloodPressureRecord) => (
                <IonItemSliding key={record.id}>
                  <IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="4">
                          <IonLabel>
                            {new Date(record.date).toLocaleDateString()}
                            <br />
                            <small>{formatTime(new Date(record.date))}</small>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="4">
                          {record.systolic}/{record.diastolic}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>

                  <IonItemOptions side="end">
                    <IonItemOption
                      style={{
                        background: '#F8333C'
                      }}
                      onClick={() =>
                        deleteBloodPressureRecord(record.id.toString())
                      }
                    >
                      <IonIcon icon={trash} slot="icon-only" />
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
          </IonItemGroup>
        </IonList>
        <IonInfiniteScroll
          // threshold="100px"
          disabled={!hasMoreItems || isFetching}
          onIonInfinite={(ev) => {
            console.info('load more')
            loadMore()
            ;(ev.target as HTMLIonInfiniteScrollElement).complete()
          }}
        >
          <IonInfiniteScrollContent loadingText="Loading more records..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        duration={10000}
        htmlAttributes={{ tabindex: undefined }}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              setShowToast(false)
            }
          }
        ]}
      />
    </IonPage>
  )
}

export default BloodPressure
