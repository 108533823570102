import './MessagesForm.css'
import { IonAccordion, IonAccordionGroup, IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonTextarea, IonToast, useIonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { SAVE_MESSAGE } from '../../components/common/DbConstants'
import { useState } from 'react'
import { closeOutline, sendOutline } from 'ionicons/icons'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'

export const MessagesForm: React.FC = () => {
  const { t } = useTranslation()

  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [toastMessage, setToastMessage] = useState(null)

  const [present] = useIonAlert()
  const history = useHistory()

  const [saveMessage] = useMutation(SAVE_MESSAGE)

  const handleSaveMessage = async () => {
    if (message) {
      try {
        const result = await saveMessage({
          variables: {
            subject,
            message
          }
        })

        setMessage('')
        setSubject('')

        if (result.data?.saveMessage) setToastMessage(t('messages.sent'))

        history.push('/actions')
      } catch (error) {
        setToastMessage(t('messages.error_sending_message'))
        console.log('Error sending message: ', error)
      }
    }
  }

  return (
    <IonAccordionGroup className="ion-margin-top ion-margin-bottom">
      <IonAccordion value="message">
        <IonItem slot="header">
          <IonLabel className="ion-padding-horizontal">
            {t('messages.bugs_suggestions')}
          </IonLabel>
        </IonItem>
        <IonList slot="content">
          <IonItem>
            <IonLabel position="floating">
              {t('messages.subject')}
            </IonLabel>
            <IonInput autocomplete='off' placeholder={t('messages.subject_placeholder')} inputMode='text' minlength={1} maxlength={128} clearInput value={subject} onIonChange={e => setSubject(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">
              {t('messages.message')}
            </IonLabel>
            <IonTextarea rows={3} cols={20} placeholder={t('messages.description_placeholder')} inputMode='text' minlength={1} maxlength={2048} value={message} onIonChange={e => setMessage(e.detail.value!)}></IonTextarea>
          </IonItem>

          <div className='center'>
            <IonItem>
              <IonButton className='margin button-send' disabled={message === ''} onClick={() =>
                present({
                  cssClass: 'my-css',
                  header: t('messages.confirm_message'),
                  message: `<h3>${subject}</h3><p>${message}</p>`,
                  buttons: [
                    t('messages.cancel'),
                    { text: t('messages.send'), handler: () => handleSaveMessage() }
                  ]
                })
              }>
                <IonIcon slot='icon-only' icon={sendOutline} />
              </IonButton>
            </IonItem>
          </div>
        </IonList>
      </IonAccordion>

      <IonToast
        isOpen={!!toastMessage}
        onDidDismiss={() => setToastMessage(null)}
        message={toastMessage || ''}
        duration={3000}
        position={'top'}
        color={'white'}
        htmlAttributes={{ tabindex: undefined }}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel'
          }
        ]}
      />
    </IonAccordionGroup>
  )
}
