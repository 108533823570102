import './MoodSelector.css'
import {
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonText,
  useIonViewWillEnter
} from '@ionic/react'
import { t } from 'i18next'
import { happy, sad } from 'ionicons/icons'
import { MOOD_HAPPY, MOOD_SAD } from './DbConstants'
import { useDispatch, useSelector } from 'react-redux'
import { setMoodSelected } from '../../redux/actions'

export const MoodSelector: React.FC = () => {
  const dispatch = useDispatch()
  const moodSelected = useSelector((state: any) => state.moodSelected)

  useIonViewWillEnter(() => {
    dispatch(setMoodSelected(null))
  })

  function handleMoodSelected (mood: string | undefined) {
    dispatch(setMoodSelected(mood))
  }

  return <div className="mood-selector ion-padding ion-margin-top">
  <IonText>{t('message.how_did_you_feel')}</IonText>
  <IonSegment onIonChange={e => handleMoodSelected(e.detail.value)} value={moodSelected}>
  <IonSegmentButton value={MOOD_HAPPY.toString()} className="happy">
    <IonIcon icon={happy} size='large'/>
    <IonLabel>{t('mood.happy')}</IonLabel>
  </IonSegmentButton>
  <IonSegmentButton value={MOOD_SAD.toString()} className="sad">
    <IonIcon icon={sad} size='large' />
    <IonLabel>{t('mood.sorry')}</IonLabel>
  </IonSegmentButton>
</IonSegment>
</div>
}

export default MoodSelector
