import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  useIonViewDidEnter,
  IonToast,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonCol,
  IonItemDivider,
  IonItemGroup,
  IonRow,
  IonChip,
  IonItemSliding,
  IonItemOption,
  IonItemOptions
} from '@ionic/react'
import { chevronBack, closeOutline, trash } from 'ionicons/icons'
import { t } from 'i18next'
import {
  useCreateGlucose,
  useGlucoseRecords,
  useDeleteGlucose
} from '../../api/glucose'
import { formatTime } from '../../utils/timeHelpers'
import { GlucoseRecord } from '../../types/interfaces'

export const Glucose: React.FC = () => {
  const history = useHistory()
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [glucose, setGlucose] = useState(0)
  const [fast, setFast] = useState(false)
  const [saveText, setSaveText] = useState(<></>)
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMessage] = useState('')
  const [offset, setOffset] = useState(0)
  const [glucoseRecords, setGlucoseRecords] = useState<GlucoseRecord[]>([])
  const [hasMoreItems, setHasMoreItems] = useState(true)

  const modal = useRef<HTMLIonModalElement>(null)

  const createGlucose = useCreateGlucose()
  const deleteGlucose = useDeleteGlucose()

  const LIMIT = 20
  const {
    data: newData,
    refetch: refetchMore,
    isFetching
  } = useGlucoseRecords(LIMIT, offset)

  useEffect(() => {
    if (newData?.data && newData.data.length > 0) {
      // Merge and deduplicate glucoseRecords, then sort by date descending.
      const newRecords = [
        ...glucoseRecords,
        ...newData.data.filter(
          (newRecord: GlucoseRecord) =>
            !glucoseRecords.find(
              (oldRecord: GlucoseRecord) => oldRecord.id === newRecord.id
            )
        )
      ].sort((a: GlucoseRecord, b: GlucoseRecord) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })

      setGlucoseRecords(newRecords)
    }
  }, [newData])

  modal.current?.addEventListener('ionModalDidPresent', () => {
    const firstInput = modal.current?.querySelector('ion-input')
    firstInput?.setFocus()
  })

  const loadMore = () => {
    if (isFetching) return

    // Assuming newData.data.length would be less than LIMIT if no more records are there.
    if (newData?.data.length < LIMIT) {
      setHasMoreItems(false)
      return
    }

    refetchMore()
    setOffset((prevOffset) => prevOffset + LIMIT)
  }

  useIonViewDidEnter(() => {
    setSaveText(<>{t('save')}</>)
  })

  async function saveGlucose() {
    // set IonSpinner on #save-glucose button
    setSaveText(<IonSpinner name="lines" />)

    // Reset toast message
    setToastMessage('')
    setShowToast(false)

    try {
      // Save glucose
      await createGlucose.mutateAsync(
        {
          measurement: glucose,
          fast,
          date
        },
        {
          onSuccess: (data) => {
            setToastMessage(t('action.glucose_saved'))
            setShowToast(true)

            // Refetch glucose records after a successful save
            setGlucoseRecords([])
            setOffset(0) // Reset offset
            refetchMore() // Manually trigger a refetch
          },
          onError: (error) => {
            console.error(error)
            setToastMessage(t('error.saving_glucose'))
            setShowToast(true)
          }
        }
      )
    } catch (error) {
      setToastMessage(t('error.saving_glucose'))
      setShowToast(true)
    } finally {
      setSaveText(<>{t('save')}</>)

      setGlucose(0)
      setFast(false)
      setDate(new Date().toISOString())
      setHasMoreItems(true)
    }
  }

  async function deleteGlucoseRecord(id: string) {
    try {
      await deleteGlucose.mutateAsync(id, {
        onSuccess: (data) => {
          setToastMessage(t('action.glucose_deleted'))
          setShowToast(true)

          // Refetch glucose records after a successful delete
          setGlucoseRecords([])
          setOffset(0) // Reset offset
          refetchMore() // Manually trigger a refetch
        },
        onError: (error) => {
          console.error(error)
          setToastMessage(t('error.deleting_glucose'))
          setShowToast(true)
        }
      })
    } catch (error) {
      setToastMessage(t('error.deleting_glucose'))
      setShowToast(true)
    }
  }

  const handleSave = useCallback(() => {
    if (!glucose || glucose < 1) {
      setToastMessage(t('error.glucose_invalid'))
      setShowToast(true)
      return
    }

    if (!date) {
      setToastMessage(t('error.date_invalid'))
      setShowToast(true)
      return
    }

    modal.current?.dismiss({}, 'save')
    saveGlucose()

    // history.push('/actions')
  }, [glucose, date, fast])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.glucose')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButton
          onClick={() => modal.current?.present()}
          expand="block"
          className="ion-margin-bottom"
          id="open-modal"
        >
          {t('action.add_glucose')}
        </IonButton>
        <IonModal ref={modal} trigger="open-modal">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => modal.current?.dismiss()}>
                  <IonIcon icon={chevronBack} />
                </IonButton>
              </IonButtons>
              <IonTitle>{t('action.add_glucose_2')}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonGrid>
              <IonList
                style={{
                  width: '100%',
                  maxWidth: '500px',
                  borderRadius: '10px'
                }}
              >
                {/* Date input */}
                <IonItem>
                  <IonLabel>{t('date')}</IonLabel>
                  <IonDatetimeButton slot="end" datetime="datetime" />
                </IonItem>
                {/* Glucose input */}
                <IonItem>
                  <IonLabel>
                    {t('action.glucose')}
                    <p style={{ fontSize: '0.8em', color: 'gray' }}>
                      {t('action.glucose_unit')}
                    </p>
                  </IonLabel>
                  <IonInput
                    slot="end"
                    type="number"
                    placeholder="0"
                    min={1}
                    onIonChange={(e) =>
                      setGlucose(parseInt(e.detail.value!, 10))
                    }
                    style={{ textAlign: 'right' }}
                  />
                </IonItem>
                {/* Checkbox for fast */}
                <IonItem>
                  <IonLabel>{t('action.fast')}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    checked={fast}
                    onIonChange={(e) => setFast(e.detail.checked)}
                    style={{ marginRight: '1em' }}
                  />
                </IonItem>
                {/* Save button */}
                <IonButton
                  expand="block"
                  onClick={handleSave}
                  className="ion-padding-start ion-padding-end ion-margin-top"
                  id="save-glucose"
                >
                  {saveText}
                </IonButton>
              </IonList>
            </IonGrid>
          </IonContent>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetime"
              showDefaultButtons={true}
              onIonChange={(e) => {
                const newValue = e.detail.value!
                const newDate = new Date(
                  Array.isArray(newValue) ? newValue[0] : newValue
                ).toISOString()
                setDate(newDate)
              }}
            />
          </IonModal>
        </IonModal>
        <hr
          style={{
            color: 'gray',
            backgroundColor: 'gray',
            height: 1,
            width: '100%'
          }}
        />
        <IonList
          className="ion-margin-top"
          style={{
            borderRadius: '5px'
          }}
        >
          <IonItemGroup>
            <IonItemDivider
              color="light"
              style={{
                fontSize: '1em',
                fontWeight: 'bold',
                color: 'gray',
                borderRadius: '4px',
                width: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0.4em auto'
              }}
              className="ion-margin-start ion-margin-end ion-text-center"
            >
              <IonLabel
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {t('title.glucose_records')}
              </IonLabel>
            </IonItemDivider>
            {glucoseRecords.length === 0 && (
              <IonItem>
                <IonLabel>{t('no_data')}</IonLabel>
              </IonItem>
            )}

            {glucoseRecords.length !== 0 &&
              glucoseRecords?.map((record: GlucoseRecord) => (
                <IonItemSliding key={record.id}>
                  <IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="4">
                          <IonLabel>
                            {new Date(record.date).toLocaleDateString()}
                            <br />
                            <small>{formatTime(new Date(record.date))}</small>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="4">
                          {record.measurement}{' '}
                          <span style={{ fontSize: '0.8em', color: 'gray' }}>
                            {t('action.glucose_unit')}
                          </span>
                        </IonCol>
                        <IonCol size="4">
                          <IonLabel>
                            {record.fast && (
                              <IonChip color="primary">
                                <IonLabel>{t('action.fast')}</IonLabel>
                              </IonChip>
                            )}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>

                  <IonItemOptions side="end">
                    <IonItemOption
                      style={{
                        background: '#F8333C'
                      }}
                      onClick={() => deleteGlucoseRecord(record.id.toString())}
                    >
                      <IonIcon icon={trash} slot="icon-only" />
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
          </IonItemGroup>
        </IonList>
        <IonInfiniteScroll
          // threshold="100px"
          disabled={!hasMoreItems || isFetching}
          onIonInfinite={(ev) => {
            console.info('load more')
            loadMore()
            ;(ev.target as HTMLIonInfiniteScrollElement).complete()
          }}
        >
          <IonInfiniteScrollContent loadingText="Loading more records..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        duration={10000}
        htmlAttributes={{ tabindex: undefined }}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              setShowToast(false)
            }
          }
        ]}
      />
    </IonPage>
  )
}

export default Glucose
