import { ITEM_SUGAR, ITEM_HISTAMINE } from '../DbConstants'

const Description = (record: any) => {
  switch (record.itemId) {
    case ITEM_SUGAR:
      return <span className="description">{record.description}</span>
    case ITEM_HISTAMINE:
      return <span className="description">{record.description}</span>
  }
}

export default Description
