import { t } from 'i18next'

export const motivationalQuotes: {[key: number]: string} = {
  0: t('motivational_quotes.0'),
  1: t('motivational_quotes.1'),
  2: t('motivational_quotes.2'),
  3: t('motivational_quotes.3'),
  4: t('motivational_quotes.4'),
  5: t('motivational_quotes.5')
}

export const MOTIVATIONAL_QUOTES_LENGTH = 6
