import './Index.css'
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { useHistory } from 'react-router'

export const Index: React.FC = () => {
  const history = useHistory()

  const goTo = (link: string) => () => {
    if (link) {
      history.push('/action/' + link)
    }
  }

  const module = (title: string, icon: string, link: string = '', width: string = '46%') => {
    return (
      <div className={`module ${link}`} onClick={goTo(link)} style={{ width: width }}>
        <div className="module-inner">
          <IonIcon icon={icon} className='action-icon' color='black' />
        </div>
        <div className='module-title'>{t(title)}</div>
      </div>
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('actions')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="actions-container">
          {module('title.my_goals', '/assets/icon/my_goals.svg', 'my_goals')}
          {module('title.records', '/assets/icon/records.svg', 'records')}
          {module('action.water', '/assets/icon/water.svg', 'water')}
          {module('action.alcohol', '/assets/icon/alcohol.svg', 'alcohol')}
          {module('action.sugar', '/assets/icon/sugar.svg', 'sugar')}
          {module('action.exercise', '/assets/icon/exercise.svg', 'exercise')}
          {module('action.custom', '/assets/icon/custom.svg', 'histamine')}
          {module('action.hunger_scale', '/assets/icon/hunger_scale.svg', 'hunger_scale')}
          {module('title.glucose', '/assets/icon/glucose.svg', 'glucose')}
          {module('title.blood_pressure', '/assets/icon/blood_pressure.svg', 'blood_pressure')}
          {module('title.follow_up', '/assets/icon/follow_up.svg', 'follow_up', '97%')}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Index
