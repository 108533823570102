import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { bookmarksOutline, person } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router'
import Alcohol from '../components/actions/Alcohol'
import Exercise from '../components/actions/Exercise'
import Histamine from '../components/actions/Histamine'
import HungerScale from '../components/actions/HungerScale'
import Index from '../components/actions/Index'
import { MyFollowUp } from '../components/actions/MyFollowUp'
import MyGoals from '../components/actions/MyGoals'
import { MyRecords } from '../components/actions/MyRecords'
import Sugar from '../components/actions/Sugar'
import Water from '../components/actions/Water'
import AddFollowUp from '../components/common/AddFollowUp'
import Profile from './Profile'
import Glucose from '../components/actions/Glucose'
import BloodPressure from '../components/actions/BloodPressure'

export const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const userData = useSelector((state: any) => state.userData)

  if (userData.isAdmin) {
    console.info('history push from dashboard to admin')
    location.href = '/admin'
  }

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/actions" component={Index} />
          <Route exact path="/action/water" component={Water} />
          <Route exact path="/action/alcohol" component={Alcohol} />
          <Route exact path="/action/sugar" component={Sugar} />
          <Route exact path="/action/exercise" component={Exercise} />
          <Route exact path="/action/records" component={MyRecords} />
          <Route exact path="/action/hunger_scale" component={HungerScale} />
          <Route exact path="/action/my_goals" component={MyGoals} />
          <Route exact path="/action/follow_up" component={MyFollowUp} />
          <Route exact path="/action/add_follow_up" component={AddFollowUp} />
          <Route exact path="/action/histamine" component={Histamine} />
          <Route exact path="/action/glucose" component={Glucose} />
          <Route exact path="/action/blood_pressure" component={BloodPressure} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/dashboard">
            <Redirect to="/actions" />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
            <IonTabButton
              tab="actions"
              href="/actions"
              style={{ marginRight: 50 }}
            >
              <IonIcon icon={bookmarksOutline} />
              <IonLabel>{t('actions')}</IonLabel>
            </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={person} />
            <IonLabel>{t('profile')}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <IonFab vertical="bottom" horizontal="center" style={{ width: 100 }}>
        <IonFabButton>
          <IonImg className='small-logo' src="/assets/icon/small-logo.png"></IonImg>
        </IonFabButton>
      </IonFab>
    </IonReactRouter>
  )
}

export default Dashboard
