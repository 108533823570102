import { gql } from '@apollo/client'

export const SORTABLE_DATE_FORMAT = 'YYYY-MM-DD'

export const ITEM_WATER = 1
export const ITEM_ALCOHOL = 2
export const ITEM_EXERCISE = 3
export const ITEM_SUGAR = 4
export const ITEM_HUNGER_SCALE = 5
export const ITEM_HISTAMINE = 6

export const MEASURE_GLASS = 1
export const MEASURE_S_BOTTLE = 2
export const MEASURE_M_BOTTLE = 3
export const MEASURE_L_BOTTLE = 4
export const MEASURE_SHOT_LIQUOR = 5
export const MEASURE_WINE = 6
export const MEASURE_BEER = 7
export const MEASURE_MIXED_DRINK = 8
export const MEASURE_DURATION_MINUTES = 9
export const MEASURE_DURATION_REPEATS = 10
export const MEASURE_XL_BOTTLE = 11

export const MOOD_HAPPY = 1
export const MOOD_SAD = 2

export const REASON_EVENT = 1
export const REASON_IRRELEVANT = 2
export const REASON_CHALLENGE = 3

export const EXERCISE_SELECTION_3_DAYS = 1
export const EXERCISE_SELECTION_HOURS = 2
export const EXERCISE_SELECTION_CUSTOM = 3

export const EXERCISE_TYPE_WALKING = 1
export const EXERCISE_TYPE_RUNNING = 2
export const EXERCISE_TYPE_BIKING = 3
export const EXERCISE_TYPE_SWIMMING = 4
export const EXERCISE_TYPE_GYM = 5
export const EXERCISE_TYPE_CONTACT_SPORT = 6
export const EXERCISE_TYPE_TEAM_SPORT = 7
export const EXERCISE_TYPE_CUSTOM = 8

export const GOAL_WATER_LITRES = 1
export const GOAL_ALCOHOL = 2
export const GOAL_SUGAR = 3
export const GOAL_EXERCISE = 4
export const GOAL_PHYSICAL = 5

export const NOTIFICATION_WATER = 1
export const NOTIFICATION_EXERCISE = 2
export const NOTIFICATION_ALCOHOL_SUGAR = 3

export const RECORD_INSERT = gql`
mutation AddRecord($itemId: Int!, $description: String, $measureId: Int, $reasonId: Int, $moodId: Int, $quantity: Float!, $createdAt: String!) {
  addRecord(itemId: $itemId, description: $description, measureId: $measureId, reasonId: $reasonId, moodId: $moodId, quantity: $quantity, createdAt: $createdAt) {
    record { itemId, description, measureId, moodId, quantity, createdAt }
    goalNotification { header, message }
  }
}
`

export const RECORD_UPDATE = gql`
mutation UpdateRecord($id: Int!, $itemId: Int!, $description: String, $measureId: Int, $reasonId: Int, $moodId: Int, $quantity: Float!, $createdAt: String) {
  updateRecord(id: $id, itemId: $itemId, description: $description, measureId: $measureId, reasonId: $reasonId, moodId: $moodId, quantity: $quantity, createdAt: $createdAt) {
    affectedRows
  }
}
`

export const GOALS_INSERT = gql`
mutation CreateGoals($data: String!) {
  createGoals(data: $data) {
    data
  }
}
`
export const USER_INSERT = gql`
mutation CreateUser($name: String!,$email: String!, $uid: String!, $isAdmin: Boolean!) {
  createUser(name: $name, email: $email, uid: $uid, isAdmin: $isAdmin) {
    name, email, uid, isAdmin
  }
}
`
export const FOLLOW_UP_INSERT = gql`
mutation AddFollowUp($appointmentDate: String!, $weight: String, $muscleMass: String, $waterPercentage: String, $viscelarFat: String) {
  addFollowUp(appointmentDate: $appointmentDate, weight: $weight, muscleMass: $muscleMass, waterPercentage: $waterPercentage, viscelarFat: $viscelarFat) {
    appointmentDate, weight, muscleMass, waterPercentage, viscelarFat
  }
}
`
export const REGULAR_USER = gql`
query AllRegularUsers {
  allRegularUsers {
    id,
    name,
    email,
    active
  }
}
`
export const MY_RECORDS = gql`
query MyRecords {
  myRecords {
    id
    itemId
    description
    measureId
    reasonId
    moodId
    quantity
    createdAt
  }
}
`
export const MY_FOLLOW_UPS = gql`
query MyFollowUps {
  myFollowUps {
    id
    appointmentDate
    weight
    muscleMass
    viscelarFat
    waterPercentage
    createdAt
  }
}
`
export const MY_GOALS = gql`
query MyGoals {
  myGoals {
    id
    goalId
    data
    startDate
  }
}
`
export const USER_RECORDS = gql`
query UserRecords($userId: Int!) {
  userRecords(userId: $userId) {
    id
    itemId
    description
    measureId
    reasonId
    moodId
    quantity
    createdAt
  }
}
`

export const MY_EXERCISE_NOTIFICATION = gql`
query MyExerciseNotification {
  myExerciseNotification {
    id
    userId
    notificationType
    configData
    active
  }
}
`

export const SAVE_EXERCISE_NOTIFICATION = gql`
mutation SaveExerciseNotification($configData: String!) {
  saveExerciseNotification(configData: $configData) {
    configData
  }
}
`

export const CHANGE_USER_ACTIVE = gql`
mutation ChangeUserActive($userId: Int!, $active: Boolean!) {
  changeUserActive(userId: $userId, active: $active) {
    id
    active
  }
}
`

export const DELETE_RECORD = gql`
mutation DeleteRecordById($id: Int!) {
  deleteRecordById(id: $id) {
    id
  }
}
`

export const SAVE_MESSAGE = gql`
mutation SaveMessage($subject: String, $message: String!) {
  saveMessage(subject: $subject, message: $message) {
    subject
    message
  }
}
`

export const MY_UNREAD_MESSAGES = gql`
query MyUnreadMessages {
  myUnreadMessages {
    count
  }
}
`

export const MY_MESSAGES = gql`
query MyMessages {
  myMessages {
    id
    userId
    subject
    message
    createdAt
    read
    user
  }
}
`

export const MY_NOTIFICATION = gql`
query MyNotification($notificationType: Int!) {
  myNotification(notificationType: $notificationType) {
    id
    userId
    notificationType
    configData
    active
  }
}
`

export const SAVE_NOTIFICATION = gql`
mutation SaveNotification($notificationType: Int!, $configData: String!, $active: Boolean) {
  saveNotification(notificationType: $notificationType, configData: $configData, active: $active) {
    notificationType
    configData
    active
  }
}
`

export const addRecordType = async (func: Function, itemId: number, measureId: number | null, reasonId: number | null, moodId: number | null, quantity: number, description: string | null = null, createdAt: string) => {
  return await func({
    variables: {
      itemId,
      description,
      measureId,
      reasonId,
      moodId,
      quantity,
      createdAt
    }
  })
}
