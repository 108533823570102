import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { useState } from 'react'
import CountableItem from '../common/CountableItem'
import {
  addRecordType,
  ITEM_WATER,
  MEASURE_GLASS,
  MEASURE_L_BOTTLE,
  MEASURE_M_BOTTLE,
  MEASURE_S_BOTTLE,
  MEASURE_XL_BOTTLE,
  RECORD_INSERT
} from '../common/DbConstants'
import { GiBeerBottle, GiWaterBottle, GiSquareBottle, GiBrandyBottle } from 'react-icons/gi'
import { MdLocalDrink } from 'react-icons/md'
import { chevronBack, waterOutline } from 'ionicons/icons'
import GoalToast from '../common/GoalToast'
import { GoalNotification } from '../../types/NotificationTypes'
import RecordDate from '../common/RecordDate'

export const Water: React.FC = () => {
  const [key, setKey] = useState(0)
  const [glasses, setGlasses] = useState(0)
  const [sBottle, setSBottle] = useState(0)
  const [mBottle, setMBottle] = useState(0)
  const [lBottle, setLBottle] = useState(0)
  const [xlBottle, setXlBottle] = useState(0)
  const [toastMsg, setToastMsg] = useState(null)
  const [goalHeader, setGoalHeader] = useState('')
  const [goalMsg, setGoalMsg] = useState('')
  const [recordDate, setRecordDate] = useState(new Date().toISOString())

  const history = useHistory()

  const reset = () => {
    setGlasses(0)
    setSBottle(0)
    setMBottle(0)
    setLBottle(0)
    setXlBottle(0)
    setKey(key + 1)
    setRecordDate(new Date().toISOString())
  }

  const [createRecord] = useMutation(RECORD_INSERT)

  const addRecord = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (glasses + sBottle + mBottle + lBottle + xlBottle === 0) {
      setToastMsg(t('message.quantity_required'))
      return
    }

    let goalNotification: GoalNotification | undefined

    if (glasses > 0) {
      const response = await addRecordType(createRecord, ITEM_WATER, MEASURE_GLASS, null, null, glasses, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (sBottle > 0) {
      const response = await addRecordType(createRecord, ITEM_WATER, MEASURE_S_BOTTLE, null, null, sBottle, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (mBottle > 0) {
      const response = await addRecordType(createRecord, ITEM_WATER, MEASURE_M_BOTTLE, null, null, mBottle, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (lBottle > 0) {
      const response = await addRecordType(createRecord, ITEM_WATER, MEASURE_L_BOTTLE, null, null, lBottle, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (xlBottle > 0) {
      const response = await addRecordType(createRecord, ITEM_WATER, MEASURE_XL_BOTTLE, null, null, xlBottle, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }

    if (goalNotification) {
      setGoalMsg(goalNotification.message)
      setGoalHeader(goalNotification.header)
    }

    history.push('/actions')
    reset()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.water')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent key={key}>
        <RecordDate recordDate={recordDate} setRecordDate={setRecordDate} />
        <div className="ion-padding-top" />
        <CountableItem
          title="item.glass"
          measure="250ml"
          itemVar={glasses}
          icon={<MdLocalDrink />}
          setItemVarFunction={setGlasses}
        />
        <CountableItem
          title="item.small_bottle"
          measure="500ml"
          itemVar={sBottle}
          icon={<GiBeerBottle />}
          setItemVarFunction={setSBottle}
        />
        <CountableItem
          title="item.medium_bottle"
          measure="750ml"
          itemVar={mBottle}
          icon={<GiWaterBottle />}
          setItemVarFunction={setMBottle}
        />
        <CountableItem
          title="item.standard_bottle"
          measure="1L"
          itemVar={lBottle}
          icon={<GiBrandyBottle />}
          setItemVarFunction={setLBottle}
        />
        <CountableItem
          title="item.large_bottle"
          measure="1.5L"
          itemVar={xlBottle}
          icon={<GiSquareBottle />}
          setItemVarFunction={setXlBottle}
        />
        <IonButton className="ion-padding" expand="block" onClick={addRecord}>
          {t('save')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg(null)}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />

      <GoalToast
        header={goalHeader}
        message={goalMsg}
        setHeader={setGoalHeader}
        setMessage={setGoalMsg}
        icon={waterOutline}
      />
    </IonPage>
  )
}

export default Water
