import { useLazyQuery } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react'
import { MY_FOLLOW_UPS } from '../common/DbConstants'
import { t } from 'i18next'
import { add, chevronBack } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { useState } from 'react'
import { FollowUps } from '../common/FollowUps'

export const MyFollowUp: React.FC = () => {
  const history = useHistory()
  const [followUps, setFollowUps] = useState([])

  const [getFollowUps] = useLazyQuery(MY_FOLLOW_UPS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setFollowUps(data?.myFollowUps)
    }
  })

  useIonViewWillEnter(async () => {
    await getFollowUps({
      fetchPolicy: 'network-only'
    })
  })

  const addFollowUp = () => {
    history.push('/action/add_follow_up')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('title.follow_up')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <FollowUps items={followUps} />
        <IonFab
          onClick={addFollowUp}
          horizontal="end"
          vertical="bottom"
          slot="fixed"
        >
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}
