import './ResetPassword.css'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonLoading,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { closeOutline } from 'ionicons/icons'
import { confirmPasswordChange } from '../firebase'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMessage] = useState('')
  const [busy, setBusy] = useState<boolean>(false)

  const urlParams = new URLSearchParams(window.location.search)
  const oobCode = urlParams.get('oobCode') || ''
  if (oobCode === '') history.push('/login')

  const validatePassword = () => {
    if (newPassword !== confirmNewPassword) {
      setToastMessage(t('error.password_mismatch'))
    } else if (newPassword.length < 6) {
      setToastMessage(t('error.password_length'))
    } else if (!/\d/.test(newPassword)) {
      setToastMessage(t('error.password_number'))
    } else {
      return true
    }
    setShowToast(true)
    return false
  }

  const handleResetPassword = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (validatePassword()) {
      setBusy(true)

      await confirmPasswordChange(oobCode, newPassword).then((response) => {
        if (response.success) {
          setToastMessage(t('message.password_change_success'))
          setTimeout(() => {
            window.location.href = '/login'
          }, 2000)
        } else {
          setNewPassword('')
          setConfirmNewPassword('')
          setToastMessage(t('error.password_change_failed'))
        }
      }).catch(() => {
        setNewPassword('')
        setConfirmNewPassword('')
        setToastMessage(t('error.password_change_failed'))
      })
      setShowToast(true)
      setBusy(false)
    }
  }

  return (
    <IonPage>
      {busy &&
        <IonLoading
          message={t('message.please_wait')}
          isOpen={busy}
          duration={0}
        />
      }

      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('action.reset_password')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IonImg
            className="ion-padding logo"
            src="/assets/logo.png"
          />
        </div>

        <form onSubmit={handleResetPassword}>
          <IonInput
            required
            className="ion-padding ion-margin-bottom"
            type="password"
            placeholder={t('field.new_password')}
            value={newPassword}
            onIonChange={(e: any) => setNewPassword(e.target.value)}
          />
          <IonInput
            required
            className="ion-padding ion-margin-bottom"
            type="password"
            placeholder={t('field.confirm_new_password')}
            value={confirmNewPassword}
            onIonChange={(e: any) => setConfirmNewPassword(e.target.value)}
          />

          <IonButton expand="block" type="submit">{t('action.reset_password')}</IonButton>
        </form>
      </IonContent>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        duration={8000}
        htmlAttributes={{ tabindex: undefined }}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              setShowToast(false)
            }
          }
        ]}
      />
    </IonPage>
  )
}

export default ResetPassword
