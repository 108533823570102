
import './Login.css'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLoading,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react'
import axios from 'axios'
import { chevronBack, closeOutline } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { handleUserAction } from '../firebase'

const ResetPasswordRequest: React.FC = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMessage] = useState('')
  const [busy, setBusy] = useState(false)
  const [buttonText, setButtonText] = useState(<></>)
  const history = useHistory()

  useIonViewDidEnter(() => {
    setButtonText(<>{t('reset_password')}</>)
  })

  async function handleResetPasswordRequest () {
    /* eslint-disable-next-line */
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (email === '') {
      setToastMessage(t('error.email_required'))
      setShowToast(true)
      return
    } if (!re.test(email)) {
      setToastMessage(t('error.email_invalid'))
      setShowToast(true)
      return
    }

    setBusy(true)
    setButtonText(<IonSpinner />)

    try {
      const response = await axios({
        method: 'POST',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url: `${process.env.REACT_APP_BACKEND_URL}/auth/reset_password`,
        data: { email: email }
      })
      const verifyPasswordReset = await handleUserAction(response.data.link)
      if (verifyPasswordReset?.success) {
        setToastMessage(t('message.check_inbox'))
        setShowToast(true)
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      } else {
        setToastMessage(t('error.reset_password'))
        setShowToast(true)
      }
    } catch (error) {
      setToastMessage(t('error.email_not_found'))
      setShowToast(true)
    }

    setButtonText(<>{t('reset_password')}</>)
    setBusy(false)
  }

  return (
    <IonPage>
      {busy && (
        <IonLoading
          message={t('message.please_wait')}
          isOpen={busy}
          duration={0}
        />
      )}
      <IonHeader>
        <IonToolbar color="primary" mode="ios">
          <IonButton mode="ios" slot="start" fill="solid" onClick={() => history.push('/login')}>
            <IonIcon icon={chevronBack} />
          </IonButton>
          <IonTitle>{t('go_back')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">

        <IonItem className="ion-margin-top ion-margin-bottom">
          <IonInput
            type='email'
            placeholder={t('email')}
            onIonChange={(e: any) => setEmail(e.target.value)}
          />
        </IonItem>
        <IonButton onClick={handleResetPasswordRequest}>{buttonText}</IonButton>
        <p>
          {t('message.already_have_account')} <Link to="/login">{t('login')}</Link>
        </p>
      </IonContent>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        duration={8000}
        htmlAttributes={{ tabindex: undefined }}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              setShowToast(false)
            }
          }
        ]}
      />
    </IonPage>
  )
}

export default ResetPasswordRequest
