import { t } from 'i18next'
import { FaWineGlassAlt, FaCocktail } from 'react-icons/fa'
import { GiGlassShot, GiBeerStein, GiBeerBottle, GiWaterBottle, GiBrandyBottle, GiSquareBottle } from 'react-icons/gi'
import { MdLocalDrink } from 'react-icons/md'
import { MEASURE_GLASS, MEASURE_SHOT_LIQUOR, MEASURE_WINE, MEASURE_BEER, MEASURE_MIXED_DRINK, MEASURE_S_BOTTLE, MEASURE_M_BOTTLE, MEASURE_L_BOTTLE, MEASURE_XL_BOTTLE, MEASURE_DURATION_MINUTES, MEASURE_DURATION_REPEATS } from '../DbConstants'

const MeasureIcon = (record: any) => {
  switch (record.measureId) {
    case MEASURE_GLASS:
      return <MdLocalDrink />
    case MEASURE_SHOT_LIQUOR:
      return <GiGlassShot />
    case MEASURE_WINE:
      return <FaWineGlassAlt />
    case MEASURE_BEER:
      return <GiBeerStein />
    case MEASURE_MIXED_DRINK:
      return <FaCocktail />
    case MEASURE_S_BOTTLE:
      return <GiBeerBottle />
    case MEASURE_M_BOTTLE:
      return <GiWaterBottle />
    case MEASURE_L_BOTTLE:
      return <GiBrandyBottle />
    case MEASURE_XL_BOTTLE:
      return <GiSquareBottle />
    case MEASURE_DURATION_MINUTES:
      return t('duration.minutes')
    case MEASURE_DURATION_REPEATS:
      return t('duration.repeats')
  }
}

export default MeasureIcon
