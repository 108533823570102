import './Alcohol.css'
import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import CountableItem from '../common/CountableItem'
import { RECORD_INSERT, addRecordType, ITEM_ALCOHOL, REASON_IRRELEVANT, MEASURE_BEER, MEASURE_MIXED_DRINK, MEASURE_SHOT_LIQUOR, MEASURE_WINE } from '../common/DbConstants'
import MoodSelector from '../common/MoodSelector'
import ReasonSelector from '../common/ReasonSelector'
import { FaCocktail, FaWineGlassAlt } from 'react-icons/fa'
import { GiGlassShot, GiBeerStein } from 'react-icons/gi'
import { chevronBack, closeOutline, warningOutline } from 'ionicons/icons'
import RecordDate from '../common/RecordDate'

import { setMotivationalQuoteIndex } from '../../redux/actions'

import { motivationalQuotes, MOTIVATIONAL_QUOTES_LENGTH } from '../common/Constants'
import GoalToast from '../common/GoalToast'
import { GoalNotification } from '../../types/NotificationTypes'

export const Alcohol: React.FC = () => {
  const [key, setKey] = useState(0)
  const [shotLiquor, setShotLiquor] = useState(0)
  const [wine, setWine] = useState(0)
  const [beer, setBeer] = useState(0)
  const [mixedDrink, setMixedDrink] = useState(0)
  const mood = useSelector((state: any) => state.moodSelected)
  const reason = useSelector((state: any) => state.reasonSelected)
  const motivationalQuoteIndex = useSelector((state: any) => state.motivationalQuoteIndex)
  const [toastMsg, setToastMsg] = useState('')
  const [toastMotivationalMsg, setToastMotivationalMsg] = useState('')
  const [goalMsg, setGoalMsg] = useState('')
  const [goalHeader, setGoalHeader] = useState('')
  const [recordDate, setRecordDate] = useState(new Date().toISOString())

  const dispatch = useDispatch()

  const history = useHistory()
  const [createRecord] = useMutation(RECORD_INSERT)

  const reset = () => {
    setShotLiquor(0)
    setWine(0)
    setBeer(0)
    setMixedDrink(0)
    setKey(key + 1)
    setRecordDate(new Date().toISOString())
  }

  const addRecord = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (shotLiquor + wine + beer + mixedDrink === 0) {
      setToastMsg(t('message.quantity_required'))
      return
    } else if (!reason) {
      setToastMsg(t('message.reason_required'))
      return
    } else if (!mood) {
      setToastMsg(t('message.mood_required'))
      return
    }

    let goalNotification: GoalNotification | undefined

    if (shotLiquor > 0) {
      const response = await addRecordType(createRecord, ITEM_ALCOHOL, MEASURE_SHOT_LIQUOR, parseInt(reason), parseInt(mood), shotLiquor, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (wine > 0) {
      const response = await addRecordType(createRecord, ITEM_ALCOHOL, MEASURE_WINE, parseInt(reason), parseInt(mood), wine, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (beer > 0) {
      const response = await addRecordType(createRecord, ITEM_ALCOHOL, MEASURE_BEER, parseInt(reason), parseInt(mood), beer, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }
    if (mixedDrink > 0) {
      const response = await addRecordType(createRecord, ITEM_ALCOHOL, MEASURE_MIXED_DRINK, parseInt(reason), parseInt(mood), mixedDrink, null, recordDate)
      goalNotification = response?.data?.addRecord?.goalNotification
    }

    if (goalNotification) {
      setGoalMsg(goalNotification.message)
      setGoalHeader(goalNotification.header)
    }

    if (parseInt(reason) === REASON_IRRELEVANT) {
      setToastMotivationalMsg(t(motivationalQuotes[motivationalQuoteIndex]))
      dispatch(setMotivationalQuoteIndex((motivationalQuoteIndex + 1) % MOTIVATIONAL_QUOTES_LENGTH))
    }

    reset()
    history.push('/actions')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.alcohol')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent key={key}>
        <RecordDate recordDate={recordDate} setRecordDate={setRecordDate} />
        <div className="ion-padding-top" />
        <CountableItem title="item.shotLiquor" itemVar={shotLiquor} icon={<GiGlassShot />} setItemVarFunction={setShotLiquor} />
        <CountableItem title="item.wine" itemVar={wine} icon={<FaWineGlassAlt />} setItemVarFunction={setWine} />
        <CountableItem title="item.beer" itemVar={beer} icon={<GiBeerStein />} setItemVarFunction={setBeer} />
        <CountableItem title="item.mixedDrink" itemVar={mixedDrink} icon={<FaCocktail />} setItemVarFunction={setMixedDrink} />
        <ReasonSelector />
        <MoodSelector />
        <IonButton className="ion-padding" expand="block" onClick={addRecord}
          style={{
            marginBottom: '5vh'
          }}
        >
          {t('save')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg('')}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />

      <IonToast
        isOpen={!!toastMotivationalMsg}
        onDidDismiss={() => setToastMotivationalMsg('')}
        message={toastMotivationalMsg || ''}
        duration={5000}
        position={'middle'}
        color={'white'}
        buttons={[
          {
            icon: closeOutline,
            role: 'cancel'
          }
        ]}
        cssClass={'motivational-toast'}
      />

      <GoalToast
        header={goalHeader}
        message={goalMsg}
        setHeader={setGoalHeader}
        setMessage={setGoalMsg}
        icon={warningOutline}
      />
    </IonPage>
  )
}

export default Alcohol
