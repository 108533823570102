
import './TimeInput.css'
import { TimeFieldProps } from '../../types/NotificationTypes'
import TimeField from 'react-simple-timefield'

export const TimeInput: React.FC<TimeFieldProps> = ({ time, setTime, index = null }) => {
  return (
    <div className='time-input'>
      <TimeField
        style={{
          border: 'none',
          fontSize: 20,
          width: 80,
          padding: '5px 10px',
          background: 'transparent',
          borderRadius: 5,
          outline: 'none'
        }}
        value={time}
        onChange={(event, value) => {
          time = value
          setTime(time, index)
        }}
      /></div>
  )
}

export default TimeInput
