import { useMutation } from '@apollo/client'
import { IonItemSliding, IonItem, IonThumbnail, IonLabel, IonItemOptions, IonItemOption, IonIcon, IonToast } from '@ionic/react'
import { t } from 'i18next'
import { calendarNumber, happy, sad, trash } from 'ionicons/icons'
import moment from 'moment'
import { Fragment, useState } from 'react'
import { ITEM_SUGAR, ITEM_EXERCISE, ITEM_ALCOHOL, ITEM_WATER, ITEM_HUNGER_SCALE, ITEM_HISTAMINE, MEASURE_GLASS, MEASURE_L_BOTTLE, MEASURE_M_BOTTLE, MEASURE_S_BOTTLE, MEASURE_XL_BOTTLE, REASON_EVENT, REASON_IRRELEVANT, MOOD_HAPPY, MOOD_SAD, DELETE_RECORD, RECORD_UPDATE } from '../DbConstants'
import { exerciseIcon, exerciseDescription } from '../ExerciseUtils'
import ChangeDateModal from './ChangeDateModal'
import ItemIcon from './ItemIcon'
import MeasureIcon from './MeasureIcon'
import Description from './Description'

const measureValue = (record: any): String => {
  switch (record.measureId) {
    case MEASURE_GLASS:
      return '250ml'
    case MEASURE_S_BOTTLE:
      return '500ml'
    case MEASURE_M_BOTTLE:
      return '750ml'
    case MEASURE_L_BOTTLE:
      return '1L'
    case MEASURE_XL_BOTTLE:
      return '1.5L'
  }
  return ''
}

const reason = (record: any) => {
  switch (record.reasonId) {
    case REASON_EVENT:
      return t('reason.event')
    case REASON_IRRELEVANT:
      return t('reason.irrelevant')
    default:
      return null
  }
}

const recordTime = (record: any) =>
  moment
    .unix(record.createdAt / 1000)
    .format(process.env.REACT_APP_TIME_FORMAT)

const mood = (record: any) => {
  switch (record.moodId) {
    case MOOD_HAPPY:
      return <IonIcon icon={happy} color="success" />
    case MOOD_SAD:
      return <IonIcon icon={sad} color="danger" />
    default:
      return null
  }
}

const RecordItem = ({ r, userData, refetch }:
  {
    r: any,
    userData: any,
    refetch: () => any
  }
) => {
  const [showModal, setShowModal] = useState(false)
  const [deleteRecordById] = useMutation(DELETE_RECORD)
  const [updateRecordById] = useMutation(RECORD_UPDATE)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showErrorToast, setShowErrorToast] = useState(false)
  const deleteRecord = async (recordId: number) => {
    const deletedRecord = await deleteRecordById({ variables: { id: recordId } })

    if (deletedRecord) {
      await refetch()
    }
  }

  const updateRecord = async (record: any) => {
    console.log('RecordItem.updateRecord', record)

    const updatedRecord = await updateRecordById({
      variables: {
        ...record
      }
    })

    if (updatedRecord) {
      await refetch()

      setShowModal(false)
      setShowSuccessToast(true)
    } else {
      setShowErrorToast(true)
    }
  }

  return (
    <Fragment>
      <IonItemSliding disabled={userData?.isAdmin} key={r.id} >
        <IonItem className="record-item">
          <IonThumbnail slot="start" className="main-icon">
            {ItemIcon(r)}
          </IonThumbnail>
          <div className="details">

            {r.itemId === ITEM_SUGAR &&
              <span className="measure-container-sugar">
                {Description(r)}
                <IonLabel className="quantity-sugar">
                  <span className='quantity'>{`${r.quantity}`}</span>
                  <p className='unit'>{'ud(s)'}</p>
                </IonLabel>
              </span>
            }

            {r.itemId === ITEM_EXERCISE &&
              <span className="measure-container-exercise">
                <span className="quantity-exercise">
                  {r.quantity > 0 ? `${r.quantity} ${MeasureIcon(r)}` : ''}
                </span>
                <span className="icon">
                  {exerciseIcon(r.description)}
                </span>
                {exerciseDescription(r.description)}
              </span>
            }

            {(r.itemId === ITEM_ALCOHOL) &&
              <div className="measure-container">
                <span className="quantity">{r.quantity}</span>
                {r.measureId && 'x'}
                <span className="measure">{MeasureIcon(r)}</span>
              </div>
            }

            {(r.itemId === ITEM_WATER) &&
              <div className="measure-container">
                <span className="quantity">{r.quantity}</span>
                {r.measureId && 'x'}
                <span className="measure">{MeasureIcon(r)}
                  <IonLabel ><p>{measureValue(r)}</p></IonLabel>
                </span>
              </div>
            }

            {r.itemId === ITEM_HUNGER_SCALE &&
              <span className="measure-container-hunger">
                <span className="quantity-hunger">
                  {r.quantity}
                </span>
                <span>{t('hunger_scale.' + r.quantity)}</span>
              </span>
            }

            {r.itemId === ITEM_HISTAMINE &&
              <span className="measure-container-histamine">
                {Description(r)}
                <IonLabel className="quantity-histamine">
                  <span className='quantity'>{`${r.quantity}`}</span>
                </IonLabel>
              </span>
            }

            {r.itemId !== ITEM_HISTAMINE && <span className="reason">{reason(r)}</span>}
            <span className="mood">{mood(r)}</span>
          </div>
          <p slot="end">{recordTime(r)}</p>
        </IonItem>
        <IonItemOptions>
          <IonItemOption
            style={{
              background: '#2B9EB3'
            }}
            onClick={() => {
              setShowModal(true)
            }}>
            <IonIcon slot="icon-only" icon={calendarNumber}></IonIcon>
          </IonItemOption>
          <IonItemOption
            style={{
              background: '#F8333C'
            }}
            onClick={() => { deleteRecord(r.id) }}>
            <IonIcon slot="icon-only" icon={trash}></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      <ChangeDateModal showModal={showModal} setShowModal={setShowModal} record={r} updateRecord={updateRecord} />
      <IonToast isOpen={showSuccessToast} message={t('action.updated')} duration={2000} />
      <IonToast isOpen={showErrorToast} message={t('action.error')} duration={2000} />
    </Fragment>
  )
}

export default RecordItem
