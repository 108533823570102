/**
 * Formats a JavaScript Date object into a time string in HH:mm format.
 *
 * @param {Date} date - The Date object to format.
 * @returns {string} The formatted time string.
 * @example
 * const date = new Date();
 * const timeString = formatTime(date);
 * console.log(timeString);  // Output could be something like "14:30"
 */
export function formatTime(date: Date) {
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedHours = hours < 10 ? '0' + hours : hours
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

  return `${formattedHours}:${formattedMinutes}`
}
