export const setUserState = (payload: any) => {
  return { type: 'SET_USER_STATE', payload }
}

export const setUserData = (payload: any) => {
  return { type: 'SET_USER_DATA', payload }
}

export const setMoodSelected = (payload: any) => {
  return { type: 'SET_MOOD_SELECTED', payload }
}

export const setReasonSelected = (payload: any) => {
  return { type: 'SET_REASON_SELECTED', payload }
}

export const setUserSelected = (payload: any) => {
  return { type: 'SET_USER_SELECTED', payload }
}

export const setMotivationalQuoteIndex = (payload: any) => {
  return { type: 'SET_MOTIVATIONAL_QUOTE_INDEX', payload }
}

export const setViewMessages = (payload: any) => {
  return { type: 'SET_VIEW_MESSAGES', payload }
}

export const setWaterNotification = (payload: any) => {
  return { type: 'SET_WATER_NOTIFICATION', payload }
}

export const setExerciseNotification = (payload: any) => {
  return { type: 'SET_EXERCISE_NOTIFICATION', payload }
}

export const setReminderNotification = (payload: any) => {
  return { type: 'SET_REMINDER_NOTIFICATION', payload }
}
