import './Message.css'
import { useQuery } from '@apollo/client'
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react'
import { t } from 'i18next'
import { chevronBack } from 'ionicons/icons'
import { useDispatch } from 'react-redux'
import { setViewMessages } from '../../redux/actions'
import { MY_MESSAGES } from '../common/DbConstants'
import SkeletonItems from '../common/SkeletonItems'
import { format } from 'date-fns'

export const Messages: React.FC = () => {
  const dispatch = useDispatch()

  const { loading, error, data, refetch } = useQuery(MY_MESSAGES)

  useIonViewWillEnter(() => {
    refetch()
  })

  if (loading) return <SkeletonItems />
  if (error) return <div>{error}</div>

  const handleDismiss = () => {
    dispatch(setViewMessages(false))
  }

  const dates = data.myMessages.map((m: { createdAt: string }) =>
    format(new Date(parseInt(m.createdAt)), 'yyyy-MM-dd')
  )

  const uniqueDates = Array.from(new Set<any>(dates)).sort().reverse()

  const accordions = uniqueDates.map((date: string) =>
    <IonAccordion key={date}>
      <IonItem slot="header">
        <IonLabel>{format(new Date(date), 'dd-MM-yyyy')}</IonLabel>
      </IonItem>
      <IonList slot='content'>
        {data.myMessages
          .filter((m: { createdAt: string }) => format(new Date(parseInt(m.createdAt)), 'yyyy-MM-dd') === date)
          .map((m: any) =>
            <IonItem lines="full" key={m.id} className='message'>
              <IonGrid>
                <IonRow>
                  <IonLabel className='message-name'>
                    <p>{m.user}</p>
                  </IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel className="ion-text-wrap">
                    <IonText color="primary">
                      <h3 className='message-subject'>{m.subject}</h3>
                    </IonText>
                    <IonText>
                      <p className='message-text'>{m.message}</p>
                    </IonText>
                  </IonLabel>
                </IonRow>
              </IonGrid>
            </IonItem>
          )
          .sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1))
        }
      </IonList>
    </IonAccordion>
  )

  return (
    <IonPage className="">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {t('messages.messages')}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonAccordionGroup value={uniqueDates[0]}>
          {accordions}
        </IonAccordionGroup>
      </IonContent>
    </IonPage>
  )
}

export default Messages
