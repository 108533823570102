import {
  IonAccordion,
  IonAccordionGroup,
  IonBackdrop,
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react'
import { search } from 'ionicons/icons'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useState } from 'react'
import moment from 'moment'

  interface ToolbarSearchProps {
    title: string
    searchItem: Function
    advanced?: boolean
  }

export const ToolbarSearch: React.FC<ToolbarSearchProps> = ({ title, searchItem, advanced = false }) => {
  const [description, setDescription] = useState('')
  const [date, setDate] = useState()
  const [backdrop, setBackdrop] = useState(false)

  const toggleSearchBar = () => {
    if (advanced) {
      const isActive = document.querySelector('#search-filters')?.classList.contains('accordion-expanded') || false
      document.querySelector<HTMLIonAccordionGroupElement>('#search-filters-container')?.requestAccordionToggle('search-filters', !isActive)
      setBackdrop(!backdrop)
    } else {
      document.querySelector('.toolbar-container ion-title')?.classList.toggle('hidden')
      document.querySelector('.toolbar-container .search-item-icon-container')?.classList.toggle('hidden')
      document.querySelector('.toolbar-container ion-searchbar')?.classList.toggle('not-visible')
    }
  }

  const onSubmitForm = (event: any) => {
    const dateFormatted = date ? moment(date).format('YYYY-MM-DD') : ''
    searchItem(event, description, dateFormatted)
    toggleSearchBar()
  }

  const resetFilters = () => {
    setDescription('')
    setDate(undefined)
    toggleSearchBar()
  }

  useIonViewDidEnter(() => {
    if (document.querySelector('.toolbar-container ion-title')?.classList.contains('hidden')) { document.querySelector('.toolbar-container ion-searchbar')?.classList.remove('not-visible') }
  })

  return (
      <IonAccordionGroup id="search-filters-container">
        <IonToolbar color="primary">
          <div className='toolbar-container'>
            <IonTitle>{title}</IonTitle>
            {!advanced &&
              <IonSearchbar
                className='not-visible'
                onIonChange={(event) => searchItem(event)}
                onIonCancel={toggleSearchBar}
                placeholder={t('action.search')}
                showCancelButton="always"
                debounce={500}
                mode="md"
              />
            }
            <div className='search-item-icon-container' onClick={toggleSearchBar}>
              <IonIcon className="search-item-icon" icon={search} slot="end" />
            </div>
          </div>
        </IonToolbar>

        {advanced &&
          <IonAccordion id="search-filters" value="search-filters">
            <IonList slot="content">
              <form id="search-filters-form" onSubmit={onSubmitForm}>
                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel>{t('field.description')}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonInput value={description} placeholder={t('field.description')} onIonChange={(e: any) => setDescription(e.target.value)}/>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                <IonItem button={true} id="open-date-input-filter">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel>{t('field.date')}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{date ? moment(date).format(process.env.REACT_APP_DATE_FORMAT) : ''}</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonPopover trigger="open-date-input-filter" showBackdrop={false} alignment="start" /* dismissOnSelect={true} */>
                    <IonDatetime presentation="date" onIonChange={(e: any) => setDate(e.detail.value!)} value={date} />
                  </IonPopover>
                </IonItem>

                <IonButton type="submit" className='save-filters' disabled={!!(!description && !date)}>
                  <IonLabel>{t('search')}</IonLabel>
                </IonButton>

                <div className='reset-filters-container'>
                  <IonButton type="reset" className='reset-filters' expand="block" onClick={resetFilters}>{t('action.reset_filters')}</IonButton>
                </div>
              </form>
            </IonList>
          </IonAccordion>
        }

        {backdrop && advanced && <IonBackdrop onIonBackdropTap={() => toggleSearchBar()} />}
      </IonAccordionGroup>
  )
}

ToolbarSearch.propTypes = {
  title: PropTypes.string.isRequired,
  searchItem: PropTypes.func.isRequired,
  advanced: PropTypes.bool
}

export default ToolbarSearch
