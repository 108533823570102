import './MyRecords.css'
import { useQuery } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react'
import SkeletonItems from '../common/SkeletonItems'
import { Records } from '../common/Records'
import { t } from 'i18next'
import { chevronBack } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { useState } from 'react'
import { Filters } from '../common/records/Filters'
import { MY_RECORDS } from '../common/DbConstants'

export const MyRecords = () => {
  const history = useHistory()
  const { loading, error, data, refetch } = useQuery(MY_RECORDS)
  const [items, setItems] = useState<any>([])

  useIonViewWillEnter(() => {
    refetch()
  })

  if (loading) return <SkeletonItems />
  if (error) return <div>{error}</div>

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('title.my_records')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Filters data={data.myRecords} setItems={setItems} />
        <Records items={items} refetch={refetch} />
      </IonContent>
    </IonPage>
  )
}
