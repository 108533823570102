import './Exercise.css'
import { useMutation, useQuery } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react'
import { t } from 'i18next'
import { chevronBack } from 'ionicons/icons'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { RECORD_INSERT, addRecordType, MY_GOALS, ITEM_EXERCISE, MEASURE_DURATION_MINUTES, MEASURE_DURATION_REPEATS, EXERCISE_TYPE_CUSTOM, GOAL_EXERCISE } from '../common/DbConstants'
import { GiSportMedal } from 'react-icons/gi'
import { exerciseIcon } from '../common/ExerciseUtils'
import RecordDate from '../common/RecordDate'

export const Exercise: React.FC = () => {
  const history = useHistory()
  const [key, setKey] = useState(0)
  const [exercises, setExercises] = useState([])
  const [checkedExercises, setCheckedExercises] = useState<any>()
  const [description, setDescription] = useState('')
  const [recordDate, setRecordDate] = useState(new Date().toISOString())

  const { refetch } = useQuery(MY_GOALS)

  const reset = () => {
    setDescription('')
    setKey(key + 1)
    setRecordDate(new Date().toISOString())
  }

  useIonViewWillEnter(async () => {
    const response = await refetch()
    const myGoals = response?.data?.myGoals ?? null

    const exercises = myGoals
      .filter((goal: { goalId: number }) => goal.goalId === GOAL_EXERCISE)
      .map((goal: { data: string }) => JSON.parse(goal.data))

    if (exercises[0]) {
      setExercises(exercises[0] || 0)
      reset()
    }
  })

  const [toastMsg, setToastMsg] = useState(null)

  const [createRecord] = useMutation(RECORD_INSERT)

  const getExerciseText = (exercise: any) => {
    if (exercise.exerciseText === 'custom') return description
    if (exercise.exercise === EXERCISE_TYPE_CUSTOM) { return exercise.exerciseText }

    return exercise.exercise.toString()
  }

  const addRecord = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!checkedExercises || checkedExercises.length === 0) {
      setToastMsg(t('message.at_least_one_exercise_required'))
      return
    }
    if (checkedExercises.length === 1 &&
      checkedExercises[0].exerciseText === 'custom' &&
      !description) {
      setToastMsg(t('message.description_required'))
      return
    }

    checkedExercises.forEach(async (exercise: any) => {
      const exerciseText = getExerciseText(exercise)
      await addRecordType(createRecord, ITEM_EXERCISE, exercise.duration, null, null, exercise.quantity || 0, exerciseText, recordDate)
    })

    reset()
    history.push('/actions')
  }

  const durationText = (duration: number) => {
    switch (duration) {
      case MEASURE_DURATION_MINUTES:
        return t('duration.minutes')
      case MEASURE_DURATION_REPEATS:
        return t('duration.repeats')
    }
  }

  const checkExercise = (event: any, exercise: any) => {
    const checked = event.detail.checked
    if (checked) {
      setCheckedExercises([...(checkedExercises || []), exercise])
    } else {
      setCheckedExercises(checkedExercises.filter(
        (e: any) => (e.exerciseText !== exercise.exerciseText && e.duration !== exercise.duration)))
    }
  }

  const exerciseItem = (e: any, i: number) => <IonItem className="exercise-item" key={i}>
    <IonThumbnail slot="start" className="main-icon">{exerciseIcon(e.exercise)}</IonThumbnail>
    <IonText>{`${e.exerciseText} (${e.quantity} ${durationText(e.duration)})`}</IonText>
    <IonCheckbox slot="end" color="primary" onIonChange={event => checkExercise(event, e)} />
  </IonItem>

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.exercise')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent key={key}>
        <RecordDate recordDate={recordDate} setRecordDate={setRecordDate} />
        <div className="ion-padding-top" />
        {exercises.map((e: any, i) => exerciseItem(e, i))}
        <IonItem className="exercise-item" key={'custom'}>
          <IonThumbnail slot="start" className="main-icon"><GiSportMedal /></IonThumbnail>
          <IonInput
            required
            placeholder={t('exercise.custom')}
            type="text"
            onInput={(e: any) => {
              setDescription(e.target.value)
            }}
            value={description}
          />
          <IonCheckbox slot="end" color="primary" onIonChange={event =>
            checkExercise(event, {
              exerciseText: 'custom'
            })} />
        </IonItem>
        <IonButton className="ion-padding" expand="block" onClick={addRecord}>
          {t('save')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg(null)}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />
    </IonPage>
  )
}

export default Exercise
