// Import the functions you need from the SDKs you need
import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, User, Auth, verifyPasswordResetCode, sendPasswordResetEmail, confirmPasswordReset, applyActionCode } from 'firebase/auth'

// Import Firebase's Messaging module
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = require(process.env.REACT_APP_FIREBASE_CONFIG as string)

const firebaseApp = initializeApp(firebaseConfig)

export function getCurrentUser () {
  return new Promise((resolve) => {
    const unsubscribe = getAuth().onAuthStateChanged(function (user) {
      resolve(user)
      unsubscribe()
    })
  })
}

export async function logoutUser () {
  const auth = getAuth()
  return await auth.signOut()
}

export async function sendVerification (user: User | null) {
  try {
    if (user) return await sendEmailVerification(user)
  } catch (error) {
    console.log('Error sending verification email: ', error)
  }
}

export async function loginUser (username: string, password: string) {
  try {
    const auth = getAuth()
    const result = await signInWithEmailAndPassword(auth, username, password)
    return { success: true, user: result.user, error: '', uid: result.user.uid }
  } catch (error) {
    console.log(error)
    return { success: false, error: error, user: null }
  }
}

export async function registerUser (username: string, password: string) {
  try {
    const auth = getAuth()
    const result = await createUserWithEmailAndPassword(auth, username, password)
    return { success: true, user: result.user, error: '' }
  } catch (error) {
    console.log(error)
    return { success: false, user: null, error: error }
  }
}

export async function getUserData () {
  const response = await axios({
    method: 'GET',
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
    url: `${process.env.REACT_APP_BACKEND_URL}/auth/my_user_data`
  })

  return response?.data
}

export async function getUserNotifications() {
  try {
    const response = await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: `${process.env.REACT_APP_BACKEND_URL}/notification/my_notifications`
    })

    return response?.data
  } catch (err: any) {
    throw new Error('Error getting user notifications: ' + err.message)
  }
}

export async function sendRegistrationToken (registrationToken: string, userId: number) {
  try {
    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: `${process.env.REACT_APP_BACKEND_URL}/firebase/registrationToken`,
      data: {
        registrationToken: registrationToken
      }
    })
  } catch (err: any) {
    throw new Error('Error sending registration token to server: ' + err.message)
  }
}

async function handleResetPassword (auth: Auth, oobCode: string) {
  return await verifyPasswordResetCode(auth, oobCode).then(async (email) => {
    return await sendPasswordResetEmail(auth, email)
      .then(() => {
        return { success: true, email: email, error: '' }
      })
      .catch((error) => {
        return { success: true, email: '', error: error }
      })
  }).catch((error) => {
    return { success: true, email: '', error: error }
  })
}

async function handleVerifyEmail (auth: Auth, oobCode: string) {
  return await applyActionCode(auth, oobCode).then(() => {
    return { success: true, email: '', error: '' }
  }).catch((error) => {
    return { success: false, email: '', error: error }
  })
}

export async function confirmPasswordChange (oobCode: string, newPassword: string) {
  const auth = getAuth()
  return await confirmPasswordReset(auth, oobCode, newPassword).then(() => {
    return { success: true, error: '' }
  }).catch((error) => {
    return { success: false, error: error.code }
  })
}

export async function handleUserAction (url: string) {
  const auth = getAuth()
  const link = new URL(url).searchParams
  const mode = link.get('mode')
  const oobCode = link.get('oobCode') || ''
  switch (mode) {
    case 'resetPassword':
      return await handleResetPassword(auth, oobCode)
    case 'verifyEmail':
      return await handleVerifyEmail(auth, oobCode)
    default:
      return { success: false, email: '', error: 'Invalid mode' }
  }
}
// Retrieve an instance of Firebase Messaging so that it can handle background messages.
const messaging = getMessaging(firebaseApp)

// requestForToken makes use of Firebase's getToken method. This let us to suscribe our app to push notifications.
// If notification permission has not been granted, this method will ask the user for notification permission. Otherwise
// it returns a token or reject the promise due to an error.
export const requestForToken = () => {
  // vapidKey: Voluntary Application Server Identification (VAPID) key
  return getToken(messaging, { vapidKey: `${process.env.REACT_APP_VAPID_KEY}` })
    .then((currentToken) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
        return currentToken
      } else {
        // Show permission request UI
        return null
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', { err })
      return null
    })
}

// Foreground Listener Setup: to handle messages when the app is in the foreground, we can make use of Firebase`s onMessage method
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: MessagePayload) => {
      console.log('payload', { payload })
      resolve(payload)
    })
  })
// We can then call the method in the Notification method.
