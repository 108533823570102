import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react'
import { t } from 'i18next'
import { chevronBack } from 'ionicons/icons'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { RECORD_INSERT, addRecordType, ITEM_HISTAMINE, REASON_IRRELEVANT } from '../common/DbConstants'
import RecordDate from '../common/RecordDate'

export const Histamine: React.FC = () => {
  const [key, setKey] = useState(0)
  const [description, setDescription] = useState('')
  const [unit, setUnit] = useState<null | number>(null)
  const [toastMsg, setToastMsg] = useState(null)
  const [recordDate, setRecordDate] = useState(new Date().toISOString())

  const history = useHistory()
  const [createRecord] = useMutation(RECORD_INSERT)

  const reset = () => {
    setDescription('')
    setUnit(null)
    setKey(key + 1)
    setRecordDate(new Date().toISOString())
  }

  const addRecord = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    const reason = REASON_IRRELEVANT // TODO: make reason not required

    if (description === '') {
      setToastMsg(t('message.description_required'))
      return
    }
    await addRecordType(createRecord, ITEM_HISTAMINE, null, reason, null, unit || 0, description, recordDate)

    reset()
    history.push('/actions')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => history.push('/actions')}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.custom')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent key={key}>
        <RecordDate recordDate={recordDate} setRecordDate={setRecordDate} />
        <div className="ion-padding-top" />

        <IonItem>
          <IonInput
            required
            placeholder={t('placeholder.description')}
            type="text"
            onInput={(e: any) => {
              setDescription(e.target.value)
            }}
            value={description}
          />
        </IonItem>
        <IonItem>
          <IonInput
            required
            placeholder={t('placeholder.units')}
            type="number"
            onInput={(e: any) => {
              setUnit(parseFloat(e.target.value))
            }}
            value={unit}
          />
        </IonItem>
        <IonButton className="ion-padding" expand="block" onClick={addRecord}>
          {t('save')}
        </IonButton>
      </IonContent>

      <IonToast
        isOpen={!!toastMsg}
        onDidDismiss={() => setToastMsg(null)}
        message={toastMsg || ''}
        duration={2000}
        htmlAttributes={{ tabindex: undefined }}
      />
    </IonPage>
  )
}

export default Histamine
