import './MessagesBadge.css'
import { IonBadge, IonButton, IonIcon } from '@ionic/react'
import { MY_UNREAD_MESSAGES } from '../../components/common/DbConstants'
import { setViewMessages } from '../../redux/actions'
import { useEffect, useState } from 'react'
import { chatbubbleEllipses } from 'ionicons/icons'
import { useLazyQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

export const MessagesBadge: React.FC = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const dispatch = useDispatch()

  const [getCountUnreadMessages] = useLazyQuery(MY_UNREAD_MESSAGES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const count = data?.myUnreadMessages ? data.myUnreadMessages.count : 0
      setUnreadMessagesCount(count)
    }
  })

  useEffect(() => {
    getCountUnreadMessages()
  }, [])

  return (
    <IonButton id="notification-button" fill='clear' slot='end' strong shape='round' onClick={() => dispatch(setViewMessages(true))}>
      <IonIcon color='dark' icon={chatbubbleEllipses}>
      </IonIcon>
      {unreadMessagesCount > 0 && <IonBadge mode='ios' id="notifications-badge" color='danger'>{unreadMessagesCount}</IonBadge>}
    </IonButton>
  )
}
