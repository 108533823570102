import {
  IonButton,
  IonChip,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar
} from '@ionic/react'
import { logoutUser } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { setUserState } from '../redux/actions'
import { useTranslation } from 'react-i18next'

import { NotificationsMenu } from '../components/notifications/NotificationsMenu'
import { useState } from 'react'
import { MessagesForm } from '../components/messages/MessagesForm'
import { MessagesBadge } from '../components/messages/MessagesBadge'
import { Messages } from '../components/messages/Messages'
import { useDeleteUser } from '../api/user' // Import the hook

export const Profile: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userData = useSelector((state: any) => state.userData)
  const showMessages = useSelector((state: any) => state.viewMessages)

  const [darkTheme, setDarkTheme] = useState(
    localStorage.getItem('darkTheme') === 'true'
  )

  const { mutate: deleteUser } = useDeleteUser() // Use the hook

  async function logout() {
    await logoutUser()
    localStorage.removeItem('AUTH_TOKEN')
    localStorage.setItem('darkTheme', 'true')
    dispatch(setUserState(null))
    window.location.href = '/login'
  }

  async function deleteProfile() {
    if (window.confirm(t('delete_profile_confirm'))) {
      deleteUser(userData.id, {
        onSuccess: () => {
          dispatch(setUserState(null)) // Reset user state
          window.location.href = '/login' // Redirect to login after deletion
        },
        onError: (error) => {
          console.error('Error deleting profile:', error)
        }
      })
    }
  }

  const toggleDarkMode = (checked: boolean) => {
    setDarkTheme(checked)
    localStorage.setItem('darkTheme', `${checked}`)
    document.body.classList.toggle('dark', checked)
  }

  const Profile = () => {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{t('profile')}</IonTitle>
            {!userData.isAdmin ? null : <MessagesBadge />}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{t('profile')}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonItem>
            <IonLabel className="ion-padding">{t('field.name')}</IonLabel>
            <IonChip color="primary">{userData.name}</IonChip>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-padding">{t('field.email')}</IonLabel>
            <IonChip color="primary">{userData.email}</IonChip>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-padding">{t('field.dark_mode')}</IonLabel>
            <IonToggle
              onIonChange={(e) => toggleDarkMode(e.detail.checked)}
              checked={darkTheme}
            />
          </IonItem>

          {userData.isAdmin ? null : <NotificationsMenu />}

          {userData.isAdmin ? null : <MessagesForm />}

          <IonButton
            onClick={logout}
            expand="block"
            fill="outline"
            className="ion-padding-start ion-padding-end ion-margin-top"
          >
            {t('logout')}
          </IonButton>
          <IonButton
            onClick={deleteProfile}
            expand="block"
            fill="outline"
            color="danger"
            className="ion-margin"
          >
            {t('delete_profile')}
          </IonButton>
        </IonContent>
      </IonPage>
    )
  }
  return showMessages ? <Messages /> : Profile()
}
export default Profile
